<!-- component header type 1 -->
<app-d-t1-header></app-d-t1-header>
<!--  -->

<div class="container _1QwuCJ mt125">
  <app-d-user [menuActive]="'profile'"></app-d-user>

  <div class="_3D9BVC">
    <div class="h4QDlo" role="main">
      <div class="_2YiVnW">
        <div class="_2w2H6X">
          <h1 class="_3iiDCN">ข้อมูลของฉัน</h1>
          <div class="TQG40c">จัดการข้อมูลส่วนตัวคุณเพื่อความปลอดภัยของบัญชีผู้ใช้นี้</div>
        </div>
        <div class="goiz2O">
          <div class="pJout2">
            <form>
              <div class="_3BlbUs">
                <div class="_1iNZU3">
                  <div class="_2PfA-y"><label>ชื่อผู้ใช้</label></div>
                  <div class="_2_JugQ">
                    <div class="_2bdFDW">
                      <div class="_3S9myJ">{{model.firstName}} {{model.lastName}}</div><button class="_2CLMxo"></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="_3BlbUs">
                <div class="_1iNZU3">
                  <div class="_2PfA-y"><label>ชื่อ</label></div>
                  <div class="_2_JugQ">
                    <div>
                      <div>
                        <input class="input-with-validator" type="text" placeholder="" maxlength="255" [(ngModel)]="model.firstName" (ngModelChange)="model.firstName = $event"  name="model.firstName">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="_3BlbUs">
                <div class="_1iNZU3">
                  <div class="_2PfA-y"><label>Email</label></div>
                  <div class="_2_JugQ">
                    <div class="_2bdFDW">
                      <div class="_3S9myJ">{{model.email}}</div>
                      <!-- <button class="_2CLMxo">เปลี่ยน</button> -->
                    </div>
                  </div>
                </div>
              </div>
              <div class="_3BlbUs">
                <div class="_1iNZU3">
                  <div class="_2PfA-y"><label>หมายเลขโทรศัพท์</label></div>
                  <div class="_2_JugQ">
                    <div class="_2bdFDW">
                      <div class="_3S9myJ">{{model.phone}}</div>
                      <!-- <button class="_2CLMxo">เปลี่ยน</button> -->
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="_3BlbUs">
              <div class="_1iNZU3">
                <div class="_2PfA-y"><label>Shop Name</label></div>
                <div class="_2_JugQ">
                  <div>
                    <div>
                      <input class="input-with-validator" type="text" placeholder="" maxlength="255"
                        value="jiravongthatthong">
                    </div>
                  </div>
                </div>
              </div>
            </div> -->
              <div class="_3BlbUs">
                <div class="_1iNZU3">
                  <div class="_2PfA-y"><label>เพศ</label></div>
                  <div class="_2_JugQ">
                    <div class="_119wWy">
                      <div class="stardust-radio-group">
                        <div class="stardust-radio" tabindex="0">
                          <div class="stardust-radio-button"
                            [ngClass]="{'stardust-radio-button--checked': model.sex === 'ชาย'}"
                            (click)="setGender('ชาย')">
                            <div class="stardust-radio-button__outer-circle">
                              <div class="stardust-radio-button__inner-circle"></div>
                            </div>
                          </div>
                          <div class="stardust-radio__content">
                            <div class="stardust-radio__label">ชาย</div>
                          </div>
                        </div>
                        <div class="stardust-radio" tabindex="0">
                          <div class="stardust-radio-button"
                            [ngClass]="{'stardust-radio-button--checked': model.sex === 'หญิง'}"
                            (click)="setGender('หญิง')">
                            <div class="stardust-radio-button__outer-circle">
                              <div class="stardust-radio-button__inner-circle"></div>
                            </div>
                          </div>
                          <div class="stardust-radio__content">
                            <div class="stardust-radio__label">หญิง</div>
                          </div>
                        </div>
                        <div class="stardust-radio" tabindex="0">
                          <div class="stardust-radio-button"
                            [ngClass]="{'stardust-radio-button--checked': model.sex === ''}" (click)="setGender('')">
                            <div class="stardust-radio-button__outer-circle">
                              <div class="stardust-radio-button__inner-circle"></div>
                            </div>
                          </div>
                          <div class="stardust-radio__content">
                            <div class="stardust-radio__label">อื่น ๆ</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="_3BlbUs">
                <div class="_1iNZU3">
                  <div class="_2PfA-y"><label>วัน/เดือน/ปี เกิด</label></div>
                  <div class="_2_JugQ">
                    <div class="_2w5iZe">
                      <div class="shopee-dropdown shopee-dropdown--has-selected dropdown" [class.open]="isOpenedDay"
                        (click)="setShowDay()">
                        <div class="shopee-dropdown__entry shopee-dropdown__entry--selected pointer">
                          <span>{{daySelected}}</span>
                          <svg enable-background="new 0 0 11 11" viewBox="0 0 11 11" x="0" y="0"
                            class="shopee-svg-icon icon-arrow-down">
                            <g>
                              <path
                                d="m11 2.5c0 .1 0 .2-.1.3l-5 6c-.1.1-.3.2-.4.2s-.3-.1-.4-.2l-5-6c-.2-.2-.1-.5.1-.7s.5-.1.7.1l4.6 5.5 4.6-5.5c.2-.2.5-.2.7-.1.1.1.2.3.2.4z">
                              </path>
                            </g>
                          </svg>
                        </div>
                        <div *ngIf="isOpenedDay" class="shopee-popover shopee-popover--default dropdown-menu"
                          style="width: 147.674px; z-index: 600;">
                          <ul class="shopee-dropdown__options" *ngFor="let i of dayList;">
                            <li class="shopee-dropdown__entry shopee-dropdown__entry--optional"
                              (click)="daySelected = i">
                              <div class="shopee-dropdown__entry-content">{{i}}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="shopee-dropdown shopee-dropdown--has-selected dropdown" [class.open]="isOpenedMonth"
                        (click)="setShowMonth()">
                        <div class="shopee-dropdown__entry shopee-dropdown__entry--selected pointer">
                          <span>{{monthSelected}}</span>
                          <svg enable-background="new 0 0 11 11" viewBox="0 0 11 11" x="0" y="0"
                            class="shopee-svg-icon icon-arrow-down">
                            <g>
                              <path
                                d="m11 2.5c0 .1 0 .2-.1.3l-5 6c-.1.1-.3.2-.4.2s-.3-.1-.4-.2l-5-6c-.2-.2-.1-.5.1-.7s.5-.1.7.1l4.6 5.5 4.6-5.5c.2-.2.5-.2.7-.1.1.1.2.3.2.4z">
                              </path>
                            </g>
                          </svg>
                        </div>
                        <div *ngIf="isOpenedMonth" class="shopee-popover shopee-popover--default dropdown-menu"
                          style="width: 147.674px; z-index: 600;">
                          <ul class="shopee-dropdown__options" *ngFor="let i of monthList;">
                            <li class="shopee-dropdown__entry shopee-dropdown__entry--optional"
                              (click)="monthSelected = i">
                              <div class="shopee-dropdown__entry-content">{{i}}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="shopee-dropdown shopee-dropdown--has-selected dropdown" [class.open]="isOpenedYear"
                        (click)="setShowYear()">
                        <div class="shopee-dropdown__entry shopee-dropdown__entry--selected pointer">
                          <span>{{yearSelected}}</span>
                          <svg enable-background="new 0 0 11 11" viewBox="0 0 11 11" x="0" y="0"
                            class="shopee-svg-icon icon-arrow-down">
                            <g>
                              <path
                                d="m11 2.5c0 .1 0 .2-.1.3l-5 6c-.1.1-.3.2-.4.2s-.3-.1-.4-.2l-5-6c-.2-.2-.1-.5.1-.7s.5-.1.7.1l4.6 5.5 4.6-5.5c.2-.2.5-.2.7-.1.1.1.2.3.2.4z">
                              </path>
                            </g>
                          </svg>
                        </div>
                        <div *ngIf="isOpenedYear" class="shopee-popover shopee-popover--default dropdown-menu"
                          style="width: 147.674px; z-index: 600;">
                          <ul class="shopee-dropdown__options" *ngFor="let i of yearList;">
                            <li class="shopee-dropdown__entry shopee-dropdown__entry--optional"
                              (click)="yearSelected = i">
                              <div class="shopee-dropdown__entry-content">{{i}}</div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="_31PFen">
                <button type="button" class="btn btn-solid-primary btn--m btn--inline" aria-disabled="false"
                  (click)="update()">บันทึก</button>
              </div>
            </form>
          </div>
          <div class="_1aIEbS">
            <div class="X1SONv">
              <div class="_1FzaUZ">
                <div class="TgSfgo" style="background-image: url(&quot;{{model.imageUrl}}&quot;);">
                </div>
              </div><input class="_2xS5eV" type="file" accept=".jpg,.jpeg,.png"><button type="button"
                class="btn btn-light btn--m btn--inline">เลือกรูป</button>
              <div class="_3Jd4Zu">
                <div class="_3UgHT6">ขนาดไฟล์: สูงสุด 1 MB</div>
                <div class="_3UgHT6">ไฟล์ที่รองรับ: .JPEG, .PNG</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
