import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DCartComponent } from './pages/cart/d-cart/d-cart.component';
import { MCartComponent } from './pages/cart/m-cart/m-cart.component';
import { DConfirmOrderComponent } from './pages/confirm-order/d-confirm-order/d-confirm-order.component';
import { MConfirmOrderComponent } from './pages/confirm-order/m-confirm-order/m-confirm-order.component';
import { DHomeComponent } from './pages/home/d-home/d-home.component';
import { MHomeComponent } from './pages/home/m-home/m-home.component';
import { DLoginComponent } from './pages/login/d-login/d-login.component';
import { MLoginComponent } from './pages/login/m-login/m-login.component';
import { DMypurchaseComponent } from './pages/mypurchase/d-mypurchase/d-mypurchase.component';
import { MMypurchaseComponent } from './pages/mypurchase/m-mypurchase/m-mypurchase.component';
import { DNotificationComponent } from './pages/notification/d-notification/d-notification.component';
import { MNotificationComponent } from './pages/notification/m-notification/m-notification.component';
import { DPaymentSuccessComponent } from './pages/payment-success/d-payment-success/d-payment-success.component';
import { MPaymentSuccessComponent } from './pages/payment-success/m-payment-success/m-payment-success.component';
import { DProductComponent } from './pages/product/d-product/d-product.component';
import { MProductComponent } from './pages/product/m-product/m-product.component';
import { DRegisterComponent } from './pages/register/d-register/d-register.component';
import { MRegisterComponent } from './pages/register/m-register/m-register.component';
import { DCategoryContentComponent } from './pages/category-content/d-category-content/d-category-content.component';
import { MCategoryContentComponent } from './pages/category-content/m-category-content/m-category-content.component';
import { DProfileComponent } from './pages/profile/d-profile/d-profile.component';
import { MProfileComponent } from './pages/profile/m-profile/m-profile.component';
import { MPaymentComponent } from './pages/payment/m-payment/m-payment.component';
import { DPaymentComponent } from './pages/payment/d-payment/d-payment.component';
import { DUserComponent } from './pages/user/d-user/d-user.component';
import { DAddressComponent } from './pages/address/d-address/d-address.component';
import { MAddressComponent } from './pages/address/m-address/m-address.component';
import { DPasswordComponent } from './pages/password/d-password/d-password.component';
import { MPasswordComponent } from './pages/password/m-password/m-password.component';
import { DShopComponent } from './pages/shop/d-shop/d-shop.component';
import { DSearchComponent } from './pages/search/d-search/d-search.component';
import { DAllShopsComponent } from './pages/all-shops/d-all-shops/d-all-shops.component';
import { DQrPaymentComponent } from './pages/qr-payment/d-qr-payment/d-qr-payment.component';
import { MShopComponent } from './pages/shop/m-shop/m-shop.component';

// , canActivate: [AuthGuard]
const routes: Routes = [
  { path: '', component: DHomeComponent },
  { path: 'm', component: MHomeComponent },
  { path: 'product', component: DProductComponent },
  { path: 'm/product', component: MProductComponent },
  { path: 'notification', component: DNotificationComponent },
  { path: 'm/notification', component: MNotificationComponent },
  { path: 'mypurchase', component: DMypurchaseComponent },
  { path: 'm/mypurchase', component: MMypurchaseComponent },
  { path: 'login', component: DLoginComponent},
  { path: 'm/login', component: MLoginComponent },
  { path: 'register', component: DRegisterComponent },
  { path: 'm/register', component: MRegisterComponent },
  { path: 'cart', component: DCartComponent },
  { path: 'm/cart', component: MCartComponent },
  { path: 'category', component: DCategoryContentComponent },
  { path: 'm/category', component: MCategoryContentComponent },
  { path: 'payment-success', component: DPaymentSuccessComponent },
  { path: 'm/payment-success', component: MPaymentSuccessComponent },
  { path: 'confirm-order', component: DConfirmOrderComponent },
  { path: 'm/confirm-order', component: MConfirmOrderComponent },
  { path: 'profile', component: DProfileComponent },
  { path: 'm/profile', component: MProfileComponent },
  { path: 'payment', component: DPaymentComponent },
  { path: 'm/payment', component: MPaymentComponent },
  { path: 'address', component: DAddressComponent },
  { path: 'm/address', component: MAddressComponent },
  { path: 'password', component: DPasswordComponent },
  { path: 'm/password', component: MPasswordComponent },
  { path: 'shop/:code', component: DShopComponent },
  { path: 'm/shop/:code', component: MShopComponent },
  { path: 'shop/search/:code', component: DSearchComponent },
  { path: 'all/shop', component: DAllShopsComponent },
  { path: 'qr-payment', component: DQrPaymentComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
