<!-- component header type 1 -->
<app-d-t1-header></app-d-t1-header>
<!--  -->

<div class="container _1QwuCJ mt125">  
    <app-d-user [menuActive]="'notification'"></app-d-user>

    <div class="_3D9BVC">
        <div class="_29d1at">
            <div class="_5MUWQn"><button class="CLujXJ">ทำเครื่องหมายว่าอ่านแล้วทั้งหมด</button></div>
            <div class="stardust-dropdown HGOGAo">
                <div class="stardust-dropdown__item-header">
                    <div class="_2C-oGw _12a-8K">
                        <div class="_2rpXcc T6YC6A">
                            <div class="_25_r8I _2JC84N">
                                <div class="_2JC84N _2GchKS"
                                    style="background-image: url('../../../../assets/img/flex-stride-13cm-2-in-1-running-shorts-X64rFj@2x.png'); background-size: contain; background-repeat: no-repeat;">
                                </div>
                            </div>
                        </div>
                        <div class="_2ueh06 KwJZyk">
                            <h1 class="v5vmwC">สินค้าถูกจัดส่งแล้ว</h1>
                            <div class="_2ddh-X">พัสดุหมายเลย XXXXXXXX ของคำสั่งซื้อ XXXXXXX จำส่งสำเร็จแล้ว</div>
                            <div class="_19Pnnu">
                                <p class="_2LvZFc">30-05-2021 11:00</p>
                            </div>
                        </div>
                        <div class="sC-lGz"><button class="_30peOe _2ZwxNa">ดูข้อมูลการสั่งซื้อ</button></div>
                    </div>
                </div>
                <div class="stardust-dropdown__item-header">
                    <div class="_2C-oGw _12a-8K">
                        <div class="_2rpXcc T6YC6A">
                            <div class="_25_r8I _2JC84N">
                                <div class="_2JC84N _2GchKS"
                                    style="background-image: url('../../../../assets/img/pro-shorts-BFfCV7@2x.png'); background-size: contain; background-repeat: no-repeat;">
                                </div>
                            </div>
                        </div>
                        <div class="_2ueh06 KwJZyk">
                            <h1 class="v5vmwC">สินค้าถูกจัดส่งแล้ว</h1>
                            <div class="_2ddh-X">พัสดุหมายเลย XXXXXXXX ของคำสั่งซื้อ XXXXXXX จำส่งสำเร็จแล้ว</div>
                            <div class="_19Pnnu">
                                <p class="_2LvZFc">30-05-2021 11:00</p>
                            </div>
                        </div>
                        <div class="sC-lGz"><button class="_30peOe _2ZwxNa">ดูข้อมูลการสั่งซื้อ</button></div>
                    </div>
                </div>
                <div class="stardust-dropdown__item-header">
                    <div class="_2C-oGw _12a-8K">
                        <div class="_2rpXcc T6YC6A">
                            <div class="_25_r8I _2JC84N">
                                <div class="_2JC84N _2GchKS"
                                    style="background-image: url('../../../../assets/img/รองเท้าวิ่งผู้-air-zoom-pegasus-38-air-kelly-anna-london-Cv6L5s@2x.png'); background-size: contain; background-repeat: no-repeat;">
                                </div>
                            </div>
                        </div>
                        <div class="_2ueh06 KwJZyk">
                            <h1 class="v5vmwC">สินค้าถูกจัดส่งแล้ว</h1>
                            <div class="_2ddh-X">พัสดุหมายเลย XXXXXXXX ของคำสั่งซื้อ XXXXXXX จำส่งสำเร็จแล้ว</div>
                            <div class="_19Pnnu">
                                <p class="_2LvZFc">30-05-2021 11:00</p>
                            </div>
                        </div>
                        <div class="sC-lGz"><button class="_30peOe _2ZwxNa">ดูข้อมูลการสั่งซื้อ</button></div>
                    </div>
                </div>
                <div class="stardust-dropdown__item-header">
                    <div class="_2C-oGw _12a-8K">
                        <div class="_2rpXcc T6YC6A">
                            <div class="_25_r8I _2JC84N">
                                <div class="_2JC84N _2GchKS"
                                    style="background-image: url('../../../../assets/img/flex-stride-13cm-2-in-1-running-shorts-X64rFj@2x.png'); background-size: contain; background-repeat: no-repeat;">
                                </div>
                            </div>
                        </div>
                        <div class="_2ueh06 KwJZyk">
                            <h1 class="v5vmwC">มีการแก้ไขรายละเอียดคำสั่งซื้อ</h1>
                            <div class="_2ddh-X">พัสดุหมายเลย XXXXXXXX ของคำสั่งซื้อ XXXXXXX จำส่งสำเร็จแล้ว</div>
                            <div class="_19Pnnu">
                                <p class="_2LvZFc">30-05-2021 11:00</p>
                            </div>
                        </div>
                        <div class="sC-lGz"><button class="_30peOe _2ZwxNa">ดูข้อมูลการสั่งซื้อ</button></div>
                    </div>
                </div>
                <div class="stardust-dropdown__item-header">
                    <div class="_2C-oGw _12a-8K">
                        <div class="_2rpXcc T6YC6A">
                            <div class="_25_r8I _2JC84N">
                                <div class="_2JC84N _2GchKS"
                                    style="background-image: url('./../../../assets/img/pro-shorts-BFfCV7@2x.png'); background-size: contain; background-repeat: no-repeat;">
                                </div>
                            </div>
                        </div>
                        <div class="_2ueh06 KwJZyk">
                            <h1 class="v5vmwC">สินค้าถูกจัดส่งแล้ว</h1>
                            <div class="_2ddh-X">พัสดุหมายเลย XXXXXXXX ของคำสั่งซื้อ XXXXXXX จำส่งสำเร็จแล้ว</div>
                            <div class="_19Pnnu">
                                <p class="_2LvZFc">30-05-2021 11:00</p>
                            </div>
                        </div>
                        <div class="sC-lGz"><button class="_30peOe _2ZwxNa">ดูข้อมูลการสั่งซื้อ</button></div>
                    </div>
                </div>
                <div class="stardust-dropdown__item-header">
                    <div class="_2C-oGw _12a-8K">
                        <div class="_2rpXcc T6YC6A">
                            <div class="_25_r8I _2JC84N">
                                <div class="_2JC84N _2GchKS"
                                    style="background-image: url('../../../../assets/img/รองเท้าวิ่งผู้-air-zoom-pegasus-38-air-kelly-anna-london-Cv6L5s@2x.png'); background-size: contain; background-repeat: no-repeat;">
                                </div>
                            </div>
                        </div>
                        <div class="_2ueh06 KwJZyk">
                            <h1 class="v5vmwC">สินค้าถูกจัดส่งแล้ว</h1>
                            <div class="_2ddh-X">พัสดุหมายเลย XXXXXXXX ของคำสั่งซื้อ XXXXXXX จำส่งสำเร็จแล้ว</div>
                            <div class="_19Pnnu">
                                <p class="_2LvZFc">30-05-2021 11:00</p>
                            </div>
                        </div>
                        <div class="sC-lGz"><button class="_30peOe _2ZwxNa">ดูข้อมูลการสั่งซื้อ</button></div>
                    </div>
                </div>
                <div class="stardust-dropdown__item-header">
                    <div class="_2C-oGw _12a-8K">
                        <div class="_2rpXcc T6YC6A">
                            <div class="_25_r8I _2JC84N">
                                <div class="_2JC84N _2GchKS"
                                    style="background-image: url('../../../../assets/img/flex-stride-13cm-2-in-1-running-shorts-X64rFj@2x.png'); background-size: contain; background-repeat: no-repeat;">
                                </div>
                            </div>
                        </div>
                        <div class="_2ueh06 KwJZyk">
                            <h1 class="v5vmwC">คุณได้ให้คะแนนเรตติ้งคำสั่งซื้อนี้แล้วหรือยัง</h1>
                            <div class="_2ddh-X">พัสดุหมายเลย XXXXXXXX ของคำสั่งซื้อ XXXXXXX จำส่งสำเร็จแล้ว</div>
                            <div class="_19Pnnu">
                                <p class="_2LvZFc">30-05-2021 11:00</p>
                            </div>
                        </div>
                        <div class="sC-lGz"><button class="_30peOe _2ZwxNa">ดูข้อมูลการสั่งซื้อ</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>