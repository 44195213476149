<div class="col-md-8">
    <img src="./assets/img/app_only.png" style="position:absolute; margin-top: -25px; margin-left: -40px;" width="68" height="63">
    <h1 mat-dialog-title>โหลดแอป SUKSAPAN Online เพื่อรับของขวัญสุดพิเศษ!</h1>
    <div mat-dialog-content>
        <h2>รับของขวัญสุดพิเศษและช้อปดีลดีๆ อีกมากมาย</h2>
    </div>
    <img src="./assets/img/store.png" width="300">
</div>
<div class="col-md-4 text-center">
    <img src="./assets/img/qr.png" width="180" height="180">
</div>