<!-- header 1 -->
<div class="col-md-12 pt-3 pb-3 bg-twotone fixed-hd">
    <div style="display: flex; height: 34px;">
        <div class="input-group">
            <input type="text" class="form-control" placeholder="12.12 Non-Stop SUKSAPAN PayDay | ส่งฟรี* ขั้นต่ำ 0.-">
            <button type="button" class="btn btn-primary">
                &nbsp;&nbsp;&nbsp;&nbsp;<span class="glyphicon glyphicon-search"></span>&nbsp;&nbsp;&nbsp;&nbsp;
            </button>
        </div>
        <div class="iconSt">
            <span class="pointer" (click)="navTo('cart')">
                <span class="glyphicon glyphicon-shopping-cart fs2"></span>
            </span>
        </div>
        <div class="iconSt">
            <span *ngIf="userData.imageUrl != null && userData.imageUrl != ''" class="pointer" (click)="navTo('profile')">
                <!-- <span class="glyphicon glyphicon-shopping-cart fs2"></span> -->
            <img src="{{userData.imageUrl}}" class="img-profile" alt="">
            </span>
            <span *ngIf="userData.imageUrl == null || userData.imageUrl == ''" class="pointer" (click)="navTo('login')">
                <span class="glyphicon glyphicon-user fs2"></span>
            </span>
        </div>
    </div>
</div>
<!-- <img src="./assets/img/we_mart.png" (click)="goToMain()" width="150"> -->