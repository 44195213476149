import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { ToastrService } from 'ngx-toastr';
import { ServiceProviderService } from 'src/app/shares/service-provider.service';
import { SignalRService } from 'src/app/shares/signal-r.service';
import { Utilities } from 'src/app/shares/utilities';

@Component({
  selector: 'app-d-qr-payment',
  templateUrl: './d-qr-payment.component.html',
  styleUrls: ['./d-qr-payment.component.css']
})
export class DQrPaymentComponent implements OnInit {
  code: string = '';
  name: string = 'QR Payment';
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value: string = 'http://core148.we-builds.com/payment-api/WeMart/Update/';
  model: any = {};
  check: boolean = false;
  constructor
    (
      private serviceProviderService: ServiceProviderService,
      public dialog: MatDialog,
      private activetedRoute: ActivatedRoute,
      private utilities: Utilities,
      private router: Router,
      private signalRService: SignalRService
    ) {
    this.activetedRoute.queryParams.subscribe(params => {
      this.code = params.code;
      this.value += this.code;
    });

    this.signalRService.startConnection();
    // this.signalRService.addTransferChartDataListener();
  }

  ngOnInit(): void {
    this.callRead();
  }

  callRead() {
    this.signalRService.hubConnection.on('paymentdata', (data) => {
      if (data.objectData.find((e) => this.code == e.code) != null) {
        if (!this.check) {
          this.serviceProviderService.post('m/cart/payment/update', { 'code': this.code }).subscribe(res => this.router.navigate(['/payment-success']));
          this.check = true;
          this.router.navigate(['payment-success']);
        }
      } else {
        console.log('not found !!!');
      }
    });
  }

  backClicked() {
    this.router.navigate(['']);
  }

}
