<app-d-t1-header></app-d-t1-header>
<div class="shop-separated mt125">

<div class="_193wCc">
  <div class="shop-search-page container">
      <a class="shop-search-page__breadcrumb-link pointer" (click)="back()"><svg
        enable-background="new 0 0 11 11" viewBox="0 0 11 11" x="0" y="0" class="shopee-svg-icon icon-arrow-left">
        <g>
          <path
            d="m8.5 11c-.1 0-.2 0-.3-.1l-6-5c-.1-.1-.2-.3-.2-.4s.1-.3.2-.4l6-5c .2-.2.5-.1.7.1s.1.5-.1.7l-5.5 4.6 5.5 4.6c.2.2.2.5.1.7-.1.1-.3.2-.4.2z">
          </path>
        </g>
      </svg>กลับไปที่หน้าร้านค้า</a>
    <div role="main" class="shop-search-page__body-section">
      <div class="shop-search-page__left-section">
        <div class="OytSNX shopee-seller-portrait">
          <div class="shopee-seller-portrait__background"
            style="background-image: url(&quot;{{shopInfo.imageUrl}}&quot;);">
          </div>
          <div class="shopee-seller-portrait__mask"></div><a class="shopee-seller-portrait__avatar"
            href="/istudiobyspvi">
            <div class="shopee-avatar _1_1Mvn">
              <!-- <div class="shopee-avatar__placeholder"><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0"
                  y="0" class="shopee-svg-icon icon-headshot">
                  <g>
                    <circle cx="7.5" cy="4.5" fill="none" r="3.8" stroke-miterlimit="10"></circle>
                    <path d="m1.5 14.2c0-3.3 2.7-6 6-6s6 2.7 6 6" fill="none" stroke-linecap="round"
                      stroke-miterlimit="10"></path>
                  </g>
                </svg></div> -->
                <img class="shopee-avatar__img"
                src="{{shopInfo.imageUrl}}">
            </div>
            <div class="shopee-seller-portrait__avatar__badge-wrapper">
              <div class="official-shop-new-badge"><img class="qutJvu"
                  src="https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg//assets/483071c49603aa7163a7f51708bff61b.png"
                  loading="lazy" width="64" height="16"></div>
            </div>
          </a>
          <div class="shopee-seller-portrait__name">{{shopInfo.title}}</div>
          <div class="shopee-seller-portrait__status">
            <div class="section-seller-overview__active-time">Active 2 ชั่วโมง ที่ผ่านมา</div>
          </div>
        </div>
        <div class="shop-search-page__filter-wrapper">
          <div class="shopee-shop-collection-filter">
            <div class="shopee-shop-collection-filter__header">หมวดหมู่ของร้านค้า</div>
            <div class="shopee-shop-collection-filter__body">
              <div class="shopee-shop-collection-filter__collection" [ngClass]="{'_2tj8CD': codeShop === ''}" (click)="setCategorySelected('','lv1Shop')">
                สินค้าทั้งหมด</div>
              <div class="shopee-shop-collection-filter__collection" *ngFor="let category of categoryList;" [ngClass]="{'_2tj8CD': codeShop === category.code}" (click)="setCategorySelected(category.code,category.lv)">{{category.title}}</div>
            </div>
          </div>
          <div class="shopee-filter-group shopee-price-range-filter shopee-price-range-filter--th">
            <div class="shopee-filter-group__header shopee-price-range-filter__header">ช่วงราคา</div>
            <div class="shopee-filter-group__body shopee-price-range-filter__edit">
              <div class="shopee-price-range-filter__inputs"><input type="text" maxlength="13"
                  class="shopee-price-range-filter__input" placeholder="฿ ใส่ราคาต่ำสุด" value="">
                <div class="shopee-price-range-filter__range-line"></div><input type="text" maxlength="13"
                  class="shopee-price-range-filter__input" placeholder="฿ ใส่ราคาสูงสุด" value="">
              </div>
            </div><button class="shopee-button-solid shopee-button-solid--primary _1-VOCH"
              style="background-color: rgb(238, 77, 45);">ตกลง</button>
          </div>
          <div class="shopee-filter-group _1OOUMW">
            <div class="shopee-filter-group__header">คะแนน</div>
            <div class="folding-items shopeee-filter-group__body folding-items--folded">
              <div class="_1LYq_U">
                <div class="rating-stars__container"><svg viewBox="0 0 9.5 8"
                    class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg><svg viewBox="0 0 9.5 8" class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg><svg viewBox="0 0 9.5 8" class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg><svg viewBox="0 0 9.5 8" class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg><svg viewBox="0 0 9.5 8" class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg></div>
              </div>
              <div class="_1LYq_U">
                <div class="rating-stars__container"><svg viewBox="0 0 9.5 8"
                    class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg><svg viewBox="0 0 9.5 8" class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg><svg viewBox="0 0 9.5 8" class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg><svg viewBox="0 0 9.5 8" class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <div class="rating-stars__star _2Jb05n" style="width: 14px; height: 14px;"><svg viewBox="0 0 30 30"
                      class="_3c6iA8">
                      <defs>
                        <linearGradient id="star__hollow" x1="50%" x2="50%" y1="0%" y2="99.0177926%">
                          <stop offset="0%" stop-color="#FFD211"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="none" fill-rule="evenodd" stroke="url(#star__hollow)" stroke-width="2"
                        d="M23.226809 28.390899l-1.543364-9.5505903 6.600997-6.8291523-9.116272-1.4059447-4.01304-8.63019038-4.013041 8.63019038-9.116271 1.4059447 6.600997 6.8291523-1.543364 9.5505903 8.071679-4.5038874 8.071679 4.5038874z">
                      </path>
                    </svg><svg viewBox="0 0 30 30" class="_3c6iA8" style="width: 0%;">
                      <defs>
                        <linearGradient id="star__solid" x1="50%" x2="50%" y1="0%" y2="100%">
                          <stop offset="0%" stop-color="#FFCA11"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="url(#star__solid)" fill-rule="evenodd"
                        d="M14.9988798 25.032153l-8.522024 4.7551739c-.4785069.2670004-.7939037.0347448-.7072938-.5012115l1.6339124-10.1109185-6.8944622-7.1327607c-.3871203-.4005006-.2499178-.7947292.2865507-.8774654l9.5090982-1.46652789L14.5740199.51703028c.2346436-.50460972.6146928-.50543408.8497197 0l4.2693588 9.18141263 9.5090986 1.46652789c.545377.0841102.680337.4700675.28655.8774654l-6.894462 7.1327607 1.633912 10.1109185c.08788.5438118-.232337.7662309-.707293.5012115l-8.5220242-4.7551739z">
                      </path>
                    </svg></div>
                </div> ขึ้นไป
              </div>
              <div class="_1LYq_U">
                <div class="rating-stars__container"><svg viewBox="0 0 9.5 8"
                    class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg><svg viewBox="0 0 9.5 8" class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg><svg viewBox="0 0 9.5 8" class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <div class="rating-stars__star _2Jb05n" style="width: 14px; height: 14px;"><svg viewBox="0 0 30 30"
                      class="_3c6iA8">
                      <defs>
                        <linearGradient id="star__hollow" x1="50%" x2="50%" y1="0%" y2="99.0177926%">
                          <stop offset="0%" stop-color="#FFD211"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="none" fill-rule="evenodd" stroke="url(#star__hollow)" stroke-width="2"
                        d="M23.226809 28.390899l-1.543364-9.5505903 6.600997-6.8291523-9.116272-1.4059447-4.01304-8.63019038-4.013041 8.63019038-9.116271 1.4059447 6.600997 6.8291523-1.543364 9.5505903 8.071679-4.5038874 8.071679 4.5038874z">
                      </path>
                    </svg><svg viewBox="0 0 30 30" class="_3c6iA8" style="width: 0%;">
                      <defs>
                        <linearGradient id="star__solid" x1="50%" x2="50%" y1="0%" y2="100%">
                          <stop offset="0%" stop-color="#FFCA11"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="url(#star__solid)" fill-rule="evenodd"
                        d="M14.9988798 25.032153l-8.522024 4.7551739c-.4785069.2670004-.7939037.0347448-.7072938-.5012115l1.6339124-10.1109185-6.8944622-7.1327607c-.3871203-.4005006-.2499178-.7947292.2865507-.8774654l9.5090982-1.46652789L14.5740199.51703028c.2346436-.50460972.6146928-.50543408.8497197 0l4.2693588 9.18141263 9.5090986 1.46652789c.545377.0841102.680337.4700675.28655.8774654l-6.894462 7.1327607 1.633912 10.1109185c.08788.5438118-.232337.7662309-.707293.5012115l-8.5220242-4.7551739z">
                      </path>
                    </svg></div>
                  <div class="rating-stars__star _2Jb05n" style="width: 14px; height: 14px;"><svg viewBox="0 0 30 30"
                      class="_3c6iA8">
                      <defs>
                        <linearGradient id="star__hollow" x1="50%" x2="50%" y1="0%" y2="99.0177926%">
                          <stop offset="0%" stop-color="#FFD211"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="none" fill-rule="evenodd" stroke="url(#star__hollow)" stroke-width="2"
                        d="M23.226809 28.390899l-1.543364-9.5505903 6.600997-6.8291523-9.116272-1.4059447-4.01304-8.63019038-4.013041 8.63019038-9.116271 1.4059447 6.600997 6.8291523-1.543364 9.5505903 8.071679-4.5038874 8.071679 4.5038874z">
                      </path>
                    </svg><svg viewBox="0 0 30 30" class="_3c6iA8" style="width: 0%;">
                      <defs>
                        <linearGradient id="star__solid" x1="50%" x2="50%" y1="0%" y2="100%">
                          <stop offset="0%" stop-color="#FFCA11"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="url(#star__solid)" fill-rule="evenodd"
                        d="M14.9988798 25.032153l-8.522024 4.7551739c-.4785069.2670004-.7939037.0347448-.7072938-.5012115l1.6339124-10.1109185-6.8944622-7.1327607c-.3871203-.4005006-.2499178-.7947292.2865507-.8774654l9.5090982-1.46652789L14.5740199.51703028c.2346436-.50460972.6146928-.50543408.8497197 0l4.2693588 9.18141263 9.5090986 1.46652789c.545377.0841102.680337.4700675.28655.8774654l-6.894462 7.1327607 1.633912 10.1109185c.08788.5438118-.232337.7662309-.707293.5012115l-8.5220242-4.7551739z">
                      </path>
                    </svg></div>
                </div> ขึ้นไป
              </div>
              <div class="_1LYq_U">
                <div class="rating-stars__container"><svg viewBox="0 0 9.5 8"
                    class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg><svg viewBox="0 0 9.5 8" class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <div class="rating-stars__star _2Jb05n" style="width: 14px; height: 14px;"><svg viewBox="0 0 30 30"
                      class="_3c6iA8">
                      <defs>
                        <linearGradient id="star__hollow" x1="50%" x2="50%" y1="0%" y2="99.0177926%">
                          <stop offset="0%" stop-color="#FFD211"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="none" fill-rule="evenodd" stroke="url(#star__hollow)" stroke-width="2"
                        d="M23.226809 28.390899l-1.543364-9.5505903 6.600997-6.8291523-9.116272-1.4059447-4.01304-8.63019038-4.013041 8.63019038-9.116271 1.4059447 6.600997 6.8291523-1.543364 9.5505903 8.071679-4.5038874 8.071679 4.5038874z">
                      </path>
                    </svg><svg viewBox="0 0 30 30" class="_3c6iA8" style="width: 0%;">
                      <defs>
                        <linearGradient id="star__solid" x1="50%" x2="50%" y1="0%" y2="100%">
                          <stop offset="0%" stop-color="#FFCA11"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="url(#star__solid)" fill-rule="evenodd"
                        d="M14.9988798 25.032153l-8.522024 4.7551739c-.4785069.2670004-.7939037.0347448-.7072938-.5012115l1.6339124-10.1109185-6.8944622-7.1327607c-.3871203-.4005006-.2499178-.7947292.2865507-.8774654l9.5090982-1.46652789L14.5740199.51703028c.2346436-.50460972.6146928-.50543408.8497197 0l4.2693588 9.18141263 9.5090986 1.46652789c.545377.0841102.680337.4700675.28655.8774654l-6.894462 7.1327607 1.633912 10.1109185c.08788.5438118-.232337.7662309-.707293.5012115l-8.5220242-4.7551739z">
                      </path>
                    </svg></div>
                  <div class="rating-stars__star _2Jb05n" style="width: 14px; height: 14px;"><svg viewBox="0 0 30 30"
                      class="_3c6iA8">
                      <defs>
                        <linearGradient id="star__hollow" x1="50%" x2="50%" y1="0%" y2="99.0177926%">
                          <stop offset="0%" stop-color="#FFD211"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="none" fill-rule="evenodd" stroke="url(#star__hollow)" stroke-width="2"
                        d="M23.226809 28.390899l-1.543364-9.5505903 6.600997-6.8291523-9.116272-1.4059447-4.01304-8.63019038-4.013041 8.63019038-9.116271 1.4059447 6.600997 6.8291523-1.543364 9.5505903 8.071679-4.5038874 8.071679 4.5038874z">
                      </path>
                    </svg><svg viewBox="0 0 30 30" class="_3c6iA8" style="width: 0%;">
                      <defs>
                        <linearGradient id="star__solid" x1="50%" x2="50%" y1="0%" y2="100%">
                          <stop offset="0%" stop-color="#FFCA11"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="url(#star__solid)" fill-rule="evenodd"
                        d="M14.9988798 25.032153l-8.522024 4.7551739c-.4785069.2670004-.7939037.0347448-.7072938-.5012115l1.6339124-10.1109185-6.8944622-7.1327607c-.3871203-.4005006-.2499178-.7947292.2865507-.8774654l9.5090982-1.46652789L14.5740199.51703028c.2346436-.50460972.6146928-.50543408.8497197 0l4.2693588 9.18141263 9.5090986 1.46652789c.545377.0841102.680337.4700675.28655.8774654l-6.894462 7.1327607 1.633912 10.1109185c.08788.5438118-.232337.7662309-.707293.5012115l-8.5220242-4.7551739z">
                      </path>
                    </svg></div>
                  <div class="rating-stars__star _2Jb05n" style="width: 14px; height: 14px;"><svg viewBox="0 0 30 30"
                      class="_3c6iA8">
                      <defs>
                        <linearGradient id="star__hollow" x1="50%" x2="50%" y1="0%" y2="99.0177926%">
                          <stop offset="0%" stop-color="#FFD211"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="none" fill-rule="evenodd" stroke="url(#star__hollow)" stroke-width="2"
                        d="M23.226809 28.390899l-1.543364-9.5505903 6.600997-6.8291523-9.116272-1.4059447-4.01304-8.63019038-4.013041 8.63019038-9.116271 1.4059447 6.600997 6.8291523-1.543364 9.5505903 8.071679-4.5038874 8.071679 4.5038874z">
                      </path>
                    </svg><svg viewBox="0 0 30 30" class="_3c6iA8" style="width: 0%;">
                      <defs>
                        <linearGradient id="star__solid" x1="50%" x2="50%" y1="0%" y2="100%">
                          <stop offset="0%" stop-color="#FFCA11"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="url(#star__solid)" fill-rule="evenodd"
                        d="M14.9988798 25.032153l-8.522024 4.7551739c-.4785069.2670004-.7939037.0347448-.7072938-.5012115l1.6339124-10.1109185-6.8944622-7.1327607c-.3871203-.4005006-.2499178-.7947292.2865507-.8774654l9.5090982-1.46652789L14.5740199.51703028c.2346436-.50460972.6146928-.50543408.8497197 0l4.2693588 9.18141263 9.5090986 1.46652789c.545377.0841102.680337.4700675.28655.8774654l-6.894462 7.1327607 1.633912 10.1109185c.08788.5438118-.232337.7662309-.707293.5012115l-8.5220242-4.7551739z">
                      </path>
                    </svg></div>
                </div> ขึ้นไป
              </div>
              <div class="_1LYq_U">
                <div class="rating-stars__container"><svg viewBox="0 0 9.5 8"
                    class="shopee-svg-icon rating-stars__star icon-rating-colored">
                    <defs>
                      <linearGradient id="ratingStarGradient" x1="50%" x2="50%" y1="0%" y2="100%">
                        <stop offset="0" stop-color="#ffca11"></stop>
                        <stop offset="1" stop-color="#ffad27"></stop>
                      </linearGradient>
                      <polygon id="ratingStar"
                        points="14.910357 6.35294118 12.4209136 7.66171903 12.896355 4.88968305 10.8823529 2.92651626 13.6656353 2.52208166 14.910357 0 16.1550787 2.52208166 18.9383611 2.92651626 16.924359 4.88968305 17.3998004 7.66171903">
                      </polygon>
                    </defs>
                    <g fill="url(#ratingStarGradient)" fill-rule="evenodd" stroke="none" stroke-width="1">
                      <g transform="translate(-876 -1270)">
                        <g transform="translate(155 992)">
                          <g transform="translate(600 29)">
                            <g transform="translate(10 239)">
                              <g transform="translate(101 10)">
                                <use stroke="#ffa727" stroke-width=".5" xlink:href="#ratingStar"></use>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                  <div class="rating-stars__star _2Jb05n" style="width: 14px; height: 14px;"><svg viewBox="0 0 30 30"
                      class="_3c6iA8">
                      <defs>
                        <linearGradient id="star__hollow" x1="50%" x2="50%" y1="0%" y2="99.0177926%">
                          <stop offset="0%" stop-color="#FFD211"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="none" fill-rule="evenodd" stroke="url(#star__hollow)" stroke-width="2"
                        d="M23.226809 28.390899l-1.543364-9.5505903 6.600997-6.8291523-9.116272-1.4059447-4.01304-8.63019038-4.013041 8.63019038-9.116271 1.4059447 6.600997 6.8291523-1.543364 9.5505903 8.071679-4.5038874 8.071679 4.5038874z">
                      </path>
                    </svg><svg viewBox="0 0 30 30" class="_3c6iA8" style="width: 0%;">
                      <defs>
                        <linearGradient id="star__solid" x1="50%" x2="50%" y1="0%" y2="100%">
                          <stop offset="0%" stop-color="#FFCA11"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="url(#star__solid)" fill-rule="evenodd"
                        d="M14.9988798 25.032153l-8.522024 4.7551739c-.4785069.2670004-.7939037.0347448-.7072938-.5012115l1.6339124-10.1109185-6.8944622-7.1327607c-.3871203-.4005006-.2499178-.7947292.2865507-.8774654l9.5090982-1.46652789L14.5740199.51703028c.2346436-.50460972.6146928-.50543408.8497197 0l4.2693588 9.18141263 9.5090986 1.46652789c.545377.0841102.680337.4700675.28655.8774654l-6.894462 7.1327607 1.633912 10.1109185c.08788.5438118-.232337.7662309-.707293.5012115l-8.5220242-4.7551739z">
                      </path>
                    </svg></div>
                  <div class="rating-stars__star _2Jb05n" style="width: 14px; height: 14px;"><svg viewBox="0 0 30 30"
                      class="_3c6iA8">
                      <defs>
                        <linearGradient id="star__hollow" x1="50%" x2="50%" y1="0%" y2="99.0177926%">
                          <stop offset="0%" stop-color="#FFD211"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="none" fill-rule="evenodd" stroke="url(#star__hollow)" stroke-width="2"
                        d="M23.226809 28.390899l-1.543364-9.5505903 6.600997-6.8291523-9.116272-1.4059447-4.01304-8.63019038-4.013041 8.63019038-9.116271 1.4059447 6.600997 6.8291523-1.543364 9.5505903 8.071679-4.5038874 8.071679 4.5038874z">
                      </path>
                    </svg><svg viewBox="0 0 30 30" class="_3c6iA8" style="width: 0%;">
                      <defs>
                        <linearGradient id="star__solid" x1="50%" x2="50%" y1="0%" y2="100%">
                          <stop offset="0%" stop-color="#FFCA11"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="url(#star__solid)" fill-rule="evenodd"
                        d="M14.9988798 25.032153l-8.522024 4.7551739c-.4785069.2670004-.7939037.0347448-.7072938-.5012115l1.6339124-10.1109185-6.8944622-7.1327607c-.3871203-.4005006-.2499178-.7947292.2865507-.8774654l9.5090982-1.46652789L14.5740199.51703028c.2346436-.50460972.6146928-.50543408.8497197 0l4.2693588 9.18141263 9.5090986 1.46652789c.545377.0841102.680337.4700675.28655.8774654l-6.894462 7.1327607 1.633912 10.1109185c.08788.5438118-.232337.7662309-.707293.5012115l-8.5220242-4.7551739z">
                      </path>
                    </svg></div>
                  <div class="rating-stars__star _2Jb05n" style="width: 14px; height: 14px;"><svg viewBox="0 0 30 30"
                      class="_3c6iA8">
                      <defs>
                        <linearGradient id="star__hollow" x1="50%" x2="50%" y1="0%" y2="99.0177926%">
                          <stop offset="0%" stop-color="#FFD211"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="none" fill-rule="evenodd" stroke="url(#star__hollow)" stroke-width="2"
                        d="M23.226809 28.390899l-1.543364-9.5505903 6.600997-6.8291523-9.116272-1.4059447-4.01304-8.63019038-4.013041 8.63019038-9.116271 1.4059447 6.600997 6.8291523-1.543364 9.5505903 8.071679-4.5038874 8.071679 4.5038874z">
                      </path>
                    </svg><svg viewBox="0 0 30 30" class="_3c6iA8" style="width: 0%;">
                      <defs>
                        <linearGradient id="star__solid" x1="50%" x2="50%" y1="0%" y2="100%">
                          <stop offset="0%" stop-color="#FFCA11"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="url(#star__solid)" fill-rule="evenodd"
                        d="M14.9988798 25.032153l-8.522024 4.7551739c-.4785069.2670004-.7939037.0347448-.7072938-.5012115l1.6339124-10.1109185-6.8944622-7.1327607c-.3871203-.4005006-.2499178-.7947292.2865507-.8774654l9.5090982-1.46652789L14.5740199.51703028c.2346436-.50460972.6146928-.50543408.8497197 0l4.2693588 9.18141263 9.5090986 1.46652789c.545377.0841102.680337.4700675.28655.8774654l-6.894462 7.1327607 1.633912 10.1109185c.08788.5438118-.232337.7662309-.707293.5012115l-8.5220242-4.7551739z">
                      </path>
                    </svg></div>
                  <div class="rating-stars__star _2Jb05n" style="width: 14px; height: 14px;"><svg viewBox="0 0 30 30"
                      class="_3c6iA8">
                      <defs>
                        <linearGradient id="star__hollow" x1="50%" x2="50%" y1="0%" y2="99.0177926%">
                          <stop offset="0%" stop-color="#FFD211"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="none" fill-rule="evenodd" stroke="url(#star__hollow)" stroke-width="2"
                        d="M23.226809 28.390899l-1.543364-9.5505903 6.600997-6.8291523-9.116272-1.4059447-4.01304-8.63019038-4.013041 8.63019038-9.116271 1.4059447 6.600997 6.8291523-1.543364 9.5505903 8.071679-4.5038874 8.071679 4.5038874z">
                      </path>
                    </svg><svg viewBox="0 0 30 30" class="_3c6iA8" style="width: 0%;">
                      <defs>
                        <linearGradient id="star__solid" x1="50%" x2="50%" y1="0%" y2="100%">
                          <stop offset="0%" stop-color="#FFCA11"></stop>
                          <stop offset="100%" stop-color="#FFAD27"></stop>
                        </linearGradient>
                      </defs>
                      <path fill="url(#star__solid)" fill-rule="evenodd"
                        d="M14.9988798 25.032153l-8.522024 4.7551739c-.4785069.2670004-.7939037.0347448-.7072938-.5012115l1.6339124-10.1109185-6.8944622-7.1327607c-.3871203-.4005006-.2499178-.7947292.2865507-.8774654l9.5090982-1.46652789L14.5740199.51703028c.2346436-.50460972.6146928-.50543408.8497197 0l4.2693588 9.18141263 9.5090986 1.46652789c.545377.0841102.680337.4700675.28655.8774654l-6.894462 7.1327607 1.633912 10.1109185c.08788.5438118-.232337.7662309-.707293.5012115l-8.5220242-4.7551739z">
                      </path>
                    </svg></div>
                </div> ขึ้นไป
              </div>
            </div>
          </div>
          <div class="shopee-filter-group">
            <div class="shopee-filter-group__header">สภาพสินค้า</div>
            <div class="folding-items shopeee-filter-group__body folding-items--folded">
              <div class="shopee-filter shopee-checkbox-filter">
                <div class="shopee-checkbox"><label class="shopee-checkbox__control"><input type="checkbox" name=""
                      value="newItem">
                    <div class="shopee-checkbox__box"><i> </i></div><span class="shopee-checkbox__label">ใหม่</span>
                  </label></div>
              </div>
              <div class="shopee-filter shopee-checkbox-filter">
                <div class="shopee-checkbox"><label class="shopee-checkbox__control"><input type="checkbox" name=""
                      value="usedItem">
                    <div class="shopee-checkbox__box"><i> </i></div><span
                      class="shopee-checkbox__label">ของมือสอง</span>
                  </label></div>
              </div>
            </div>
          </div>
          <div class="shopee-filter-group">
            <div class="shopee-filter-group__header">ช่องทางการชำระเงิน</div>
            <div class="folding-items shopeee-filter-group__body folding-items--folded">
              <div class="shopee-filter shopee-checkbox-filter">
                <div class="shopee-checkbox"><label class="shopee-checkbox__control"><input type="checkbox" name=""
                      value="payCOD">
                    <div class="shopee-checkbox__box"><i> </i></div><span
                      class="shopee-checkbox__label">เก็บเงินปลายทาง</span>
                  </label></div>
              </div>
              <div class="shopee-filter shopee-checkbox-filter">
                <div class="shopee-checkbox"><label class="shopee-checkbox__control"><input type="checkbox" name=""
                      value="payCreditCard">
                    <div class="shopee-checkbox__box"><i> </i></div><span
                      class="shopee-checkbox__label">บัตรเครดิต</span>
                  </label></div>
              </div>
              <div>
                <div style="height: 1px;"></div>
              </div>
            </div>
          </div>
          <div class="shopee-filter-group">
            <div class="shopee-filter-group__header">บริการและโปรโมชั่น</div>
            <div class="folding-items shopeee-filter-group__body folding-items--folded">
              <div class="shopee-filter shopee-checkbox-filter">
                <div class="shopee-checkbox"><label class="shopee-checkbox__control"><input type="checkbox" name=""
                      value="withDiscount">
                    <div class="shopee-checkbox__box"><i> </i></div><span
                      class="shopee-checkbox__label">พร้อมส่วนลด</span>
                  </label></div>
              </div>
              <div>
                <div style="height: 1px;"></div>
              </div>
              <div>
                <div style="height: 1px;"></div>
              </div>
              <div class="shopee-filter shopee-checkbox-filter">
                <div class="shopee-checkbox"><label class="shopee-checkbox__control"><input type="checkbox" name=""
                      value="freeShipping">
                    <div class="shopee-checkbox__box"><i> </i></div><span
                      class="shopee-checkbox__label">ฟรีค่าจัดส่ง</span>
                  </label></div>
              </div>
              <div class="stardust-dropdown folding-items__toggle">
                <div class="stardust-dropdown__item-header">
                  <div class="shopee-filter-group__toggle-btn">เพิ่มเติม<svg enable-background="new 0 0 11 11"
                      viewBox="0 0 11 11" x="0" y="0" class="shopee-svg-icon icon-arrow-down">
                      <g>
                        <path
                          d="m11 2.5c0 .1 0 .2-.1.3l-5 6c-.1.1-.3.2-.4.2s-.3-.1-.4-.2l-5-6c-.2-.2-.1-.5.1-.7s.5-.1.7.1l4.6 5.5 4.6-5.5c.2-.2.5-.2.7-.1.1.1.2.3.2.4z">
                        </path>
                      </g>
                    </svg></div>
                </div>
                <div class="stardust-dropdown__item-body">
                  <div class="folding-items__folded-items">
                    <div class="shopee-filter shopee-checkbox-filter">
                      <div class="shopee-checkbox"><label class="shopee-checkbox__control"><input type="checkbox"
                            name="" value="hasLowestPriceGuarantee">
                          <div class="shopee-checkbox__box"><i> </i></div><span
                            class="shopee-checkbox__label">ยืนยันราคาถูกที่สุด</span>
                        </label></div>
                    </div>
                    <div class="shopee-filter shopee-checkbox-filter">
                      <div class="shopee-checkbox"><label class="shopee-checkbox__control"><input type="checkbox"
                            name="" value="wholesale">
                          <div class="shopee-checkbox__box"><i> </i></div><span
                            class="shopee-checkbox__label">ขายส่ง</span>
                        </label></div>
                    </div>
                    <div class="shopee-filter shopee-checkbox-filter">
                      <div class="shopee-checkbox"><label class="shopee-checkbox__control"><input type="checkbox"
                            name="" value="filterVideo">
                          <div class="shopee-checkbox__box"><i> </i></div><span
                            class="shopee-checkbox__label">มีวิดีโอ</span>
                        </label></div>
                    </div>
                    <div class="shopee-filter shopee-checkbox-filter">
                      <div class="shopee-checkbox"><label class="shopee-checkbox__control"><input type="checkbox"
                            name="" value="preOrder">
                          <div class="shopee-checkbox__box"><i> </i></div><span
                            class="shopee-checkbox__label">สินค้าพร้อมจัดส่ง</span>
                        </label></div>
                    </div>
                    <div>
                      <div style="height: 1px;"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><button class="shopee-button-solid shopee-button-solid--primary fAQIgA"
            style="background-color: rgb(238, 77, 45);">ลบทั้งหมด</button>
        </div>
      </div>
      <div class="shop-search-page__right-section">
        <div class="shop-all-product-view">
          <div class="shopee-sort-bar"><span class="shopee-sort-bar__label">เรียงโดย</span>
            <div class="shopee-sort-by-options">
              <div class="shopee-sort-by-options__option shopee-sort-by-options__option--selected"
                style="background-color: rgb(238, 77, 45);">ยอดนิยม</div>
              <div class="shopee-sort-by-options__option">ล่าสุด</div>
              <div class="shopee-sort-by-options__option">สินค้าขายดี</div>
              <div>
                <div class="select-with-status">
                  <div class="select-with-status__holder select-with-status__box-shadow"><span
                      class="select-with-status__placeholder">ราคา</span><span><svg viewBox="0 0 10 6"
                        class="shopee-svg-icon icon-arrow-down-small">
                        <path
                          d="M9.7503478 1.37413402L5.3649665 5.78112957c-.1947815.19574157-.511363.19651982-.7071046.00173827a.50153763.50153763 0 0 1-.0008702-.00086807L.2050664 1.33007451l.0007126-.00071253C.077901 1.18820749 0 1.0009341 0 .79546595 0 .35614224.3561422 0 .7954659 0c.2054682 0 .3927416.07790103.5338961.20577896l.0006632-.00066318.0226101.02261012a.80128317.80128317 0 0 1 .0105706.0105706l3.3619016 3.36190165c.1562097.15620972.4094757.15620972.5656855 0a.42598723.42598723 0 0 0 .0006944-.00069616L8.6678481.20650022l.0009529.0009482C8.8101657.07857935 8.9981733 0 9.2045341 0 9.6438578 0 10 .35614224 10 .79546595c0 .20495443-.077512.39180497-.2048207.53283641l.0003896.00038772-.0096728.00972053a.80044712.80044712 0 0 1-.0355483.03572341z"
                          fill-rule="nonzero"></path>
                      </svg></span>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="shopee-mini-page-controller">
              <div class="shopee-mini-page-controller__state"><span
                  class="shopee-mini-page-controller__current">1</span>/<span
                  class="shopee-mini-page-controller__total">5</span></div><button
                class="shopee-button-outline shopee-mini-page-controller__prev-btn shopee-button-outline--disabled"
                disabled=""><svg viewBox="0 0 7 11" class="shopee-svg-icon icon-arrow-left-small">
                  <path
                    d="M4.694078 9.8185598L.2870824 5.4331785c-.1957415-.1947815-.1965198-.511363-.0017382-.7071046a.50867033.50867033 0 0 1 .000868-.0008702L4.7381375.2732784 4.73885.273991c.1411545-.127878.3284279-.205779.5338961-.205779.4393237 0 .7954659.3561422.7954659.7954659 0 .2054682-.077901.3927416-.205779.5338961l.0006632.0006632-.0226101.0226101a.80174653.80174653 0 0 1-.0105706.0105706L2.4680138 4.7933195c-.1562097.1562097-.1562097.4094757 0 .5656855a.45579485.45579485 0 0 0 .0006962.0006944l3.3930018 3.3763607-.0009482.0009529c.128869.1413647.2074484.3293723.2074484.5357331 0 .4393237-.3561422.7954659-.7954659.7954659-.2049545 0-.391805-.077512-.5328365-.2048207l-.0003877.0003896-.0097205-.0096728a.80042023.80042023 0 0 1-.0357234-.0355483z"
                    fill-rule="nonzero"></path>
                </svg></button><button class="shopee-button-outline shopee-mini-page-controller__next-btn"><svg
                  viewBox="0 0 7 11" class="shopee-svg-icon icon-arrow-right-small">
                  <path
                    d="M2.305922 9.81856l4.4069956-4.385381c.1957415-.194782.1965198-.511364.0017382-.707105a.26384055.26384055 0 0 0-.000868-.00087L2.2618625.273278 2.26115.273991C2.1199955.146113 1.9327221.068212 1.7272539.068212c-.4393237 0-.7954659.356142-.7954659.795466 0 .205468.077901.392741.205779.533896l-.0006632.000663.0226101.02261c.0034906.003557.0070143.00708.0105706.010571L4.5319862 4.79332c.1562097.156209.1562097.409475 0 .565685-.0002318.000232-.0004639.000463-.0006962.000694L1.1382882 8.73606l.0009482.000953c-.128869.141365-.2074484.329372-.2074484.535733 0 .439324.3561422.795466.7954659.795466.2049545 0 .391805-.077512.5328365-.204821l.0003877.00039.0097205-.009673c.012278-.011471.0241922-.023327.0357234-.035548z"
                    fill-rule="nonzero"></path>
                </svg></button>
            </div>
          </div>
          <div class="shopee-header-section__content">
            <div class="row">
              <product-card class="col-xs-2-4" *ngFor="let item of model" [model]="item"></product-card>
            </div>
          </div>
          <!-- <div class="shopee-page-controller"><button class="shopee-icon-button shopee-icon-button--left "><svg
                enable-background="new 0 0 11 11" viewBox="0 0 11 11" x="0" y="0"
                class="shopee-svg-icon icon-arrow-left">
                <g>
                  <path
                    d="m8.5 11c-.1 0-.2 0-.3-.1l-6-5c-.1-.1-.2-.3-.2-.4s.1-.3.2-.4l6-5c .2-.2.5-.1.7.1s.1.5-.1.7l-5.5 4.6 5.5 4.6c.2.2.2.5.1.7-.1.1-.3.2-.4.2z">
                  </path>
                </g>
              </svg></button><button class="shopee-button-solid shopee-button-solid--primary ">1</button><button
              class="shopee-button-no-outline">2</button><button class="shopee-button-no-outline">3</button><button
              class="shopee-button-no-outline">4</button><button class="shopee-button-no-outline">5</button><button
              class="shopee-icon-button shopee-icon-button--right "><svg enable-background="new 0 0 11 11"
                viewBox="0 0 11 11" x="0" y="0" class="shopee-svg-icon icon-arrow-right">
                <path
                  d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z">
                </path>
              </svg></button>
            </div> -->
        </div>
      </div>
    </div>
  </div>
</div>
