<!-- component header type 1 -->
<app-m-t1-header></app-m-t1-header>

<!-- banner -->
<div style="display: flex;margin-top: 50px;">
    <carousel *ngIf="isBannerAvailable" [images]="images" [height]="240" [cellWidth]="'100%'" [dots]="true" [arrows]="false" [autoplay]="true" [loop]="true"></carousel>
</div>
<!-- <div class="col-md-12 bg-white">
  <div class="row">
    <div class="col-md-12 p-0 zidx1">
      <carousel *ngIf="isBannerAvailable" [images]="images" [height]="240" [cellWidth]="'100%'" [dots]="true"
        [arrows]="false" [autoplay]="true" [loop]="true"></carousel>
    </div>
    <div class="col-md-3 p-0 pd2">
      <div>
        <img class="d-block w-100 " src="{{images[1].path}}" height="119">
      </div>
      <div class="pt-1">
        <img class="d-block w-100 " src="{{images[2].path}}" height="118">
      </div>
    </div>
  </div>
</div> -->

<!-- shortcut menu -->
<div class="bg-white scroll" style="display: flex;height: 110px;">
    <div class="pointer" style="display: flex;width: 80px;text-align: center;padding: 10px 5px 10px 5px;" *ngFor="let item of shopList | slice:0:8;">
        <div style="width: 80px;">
            <div class="img-box" style="background-image: url(&quot;{{item.imageUrl}}&quot;);" (click)="navTo(item.code)"></div>
            <div class="tssug">{{item.title}}</div>
        </div>
    </div>
</div>
<!-- <div class="col-md-12 bg-white">
  <div class="row">
    <div class="col-md-1"></div>
    <div class="col-md-10">
      <div class="row pb-5 scroll">
        <table class="w100p">
          <tr>
            <td class="pointer td1 w12-5p" *ngFor="let item of shopList | slice:0:8;">
              <div class="img-box" style="background-image: url(&quot;{{item.imageUrl}}&quot;);"
                (click)="navTo(item.code)"></div>
            </td>
          </tr>
          <tr>
            <td class="pointer td1 w12-5p" *ngFor="let item of shopList| slice:0:4;" style="vertical-align:middle;">
              <div class="pt-2" (click)="navTo(item.code)">{{item.title}}</div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="col-md-1"></div>
  </div>
</div> -->

<!-- banner download -->
<div class="col-md-12 p-4">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 pointer text-center p-0" (click)="openDownloadDialog()">
            <img style="width:100%" src="./assets/img/banner_download_wm.png">
            <!-- <div class="h100p"> -->
            <!-- <br><br> -->
            <!-- <h1>ดาวน์โหลดแอป SUKSAPAN เพื่อรับส่วนลดหรือสินค้าฟรี</h1> -->
            <!-- <img style="width:100%" src="./assets/img/banner_download.png"> -->
            <!-- </div> -->
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- หมวดหมู่ -->
<div class="col-md-12 p-4 pt-2">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 pt-4 bg-white">
            <h3>&nbsp;&nbsp;&nbsp;&nbsp;หมวดหมู่</h3>
            <div class="row pt-4 scroll">
                <table class="w100p">
                    <tr>
                        <td class="pointer td2 w10p" *ngFor="let item of listCategoryLv1 | slice:0:8;" (click)="goToCategory(item.code)">
                            <img class="rcorners1" [src]="item.imageUrl" width="70" height="70">
                            <div class="text1 wid100">{{item.title}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pointer td2 w10p" *ngFor="let item of  listCategoryLv1 | slice:8:16;" (click)="goToCategory(item.code)">
                            <img class="rcorners1" [src]="item.imageUrl" width="70" height="70">
                            <div class="text1 wid100 p-3">{{item.title}}</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- flash sale -->
<div class="col-md-12 p-4 pt-2">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 pt-4 bg-white">
            <div class="mb5">
                &nbsp;&nbsp;&nbsp;&nbsp;<img src="./assets/img/flash_sale.gif" width="100" height="30"><span class="fs1d2">&nbsp;&nbsp;&nbsp;&nbsp;<b>{{daysToDday}} วัน {{hoursToDday}} ชั่วโมง {{minutesToDday}} นาที
            {{secondsToDday}} วินาที</b></span>
            </div>
            <div class="scroll" style="display: flex;">
                <div style="width: 180px;margin: 0 7.5px;" *ngFor="let item of flashSaleModel;">
                    <img class="rcorners-flashsale img" [src]="item.imageUrl" width="180" height="180" style="object-fit:contain; padding: 0 10px;">
                    <div>
                        <p class="ttex" style="margin: 0 10px;">
                            {{item.title}}
                        </p>
                        <div class="progress" style="height: 20px;">
                            <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- list item load more -->
<!-- <product-card class="col-xs-2" *ngFor="let item of listProduct" [model]="item"></product-card> -->

<div class="col-md-12 p-4 pt-2">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10" style="padding: 0px;">
            <div class="search-results" infinite-scroll [infiniteScrollDistance]="5" [infiniteScrollThrottle]="500" (scrolled)="onScrollDown()">
                <product-card class="col-xs-6 p-0" *ngFor="let item of listProduct" [model]="item"></product-card>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>