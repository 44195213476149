<div class="_1Zeqz9">
  <div class="_1k80K2 _2GgEho _2Jjc-J">
    <div class="_3frbLs _2nXmFs _2uyPF1">
      <div class="_2WQCW5">
        <div class="_1ds64- _2Jjc-J">
          <div class="mytS2m"></div>
        </div>
      </div>
    </div>
    <div class="FCu0cQ _2NW6mG _2uyPF1">
      <div class="_16SUzs">
        <div class="_3eB1uG">
          <div class="_1Hi5GR _11A33b _3_nmjc">ส่วนลด 10% ขั้นต่ำ ฿3,000 สูงสุด ฿300</div>
          <div></div><span class="MUoVIu _2tHkAM _1hJ2z_ OK8eXO">
            <div class="_2VFlvK"><span class="_5BU55m _2a2S8T">ใช้ได้ก่อน: 20.08.2021</span></div>
          </span>
          <div class="_3AyyzW"></div>
        </div>
      </div>
      <div class="bXdXbN">
        <div class="_3N_ktJ"><button type="button"
            class="btn btn-solid-primary btn--s btn--inline _3NkwIf _1-fCmD GZT3IS"
            aria-disabled="false">เก็บโค้ด</button></div>
      </div>
    </div>
  </div>
</div>
