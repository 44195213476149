import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { interval, Subscription } from 'rxjs';
import { DownloadDialogComponent } from 'src/app/popups/download-dialog/download-dialog.component';
import { MainDialogComponent } from 'src/app/popups/main-dialog/main-dialog.component';
import { ServiceProviderService } from 'src/app/shares/service-provider.service';

@Component({
  selector: 'app-m-home',
  templateUrl: './m-home.component.html',
  styleUrls: ['./m-home.component.css']
})
export class MHomeComponent implements OnInit {

  flashSaleModel: any = [];
  criteriaModelGoods: any = {};
  code: string = '';
  listProduct: any = [];
  listCategoryLv1: any = [];

  limit:number = 10;

  // nisVersion = nisPackage.dependencies["ngx-infinite-scroll"];

  images = [
    // {path: 'https://mdbootstrap.com/img/Photos/Slides/img%20(9).jpg'},
    // {path: 'https://mdbootstrap.com/img/Photos/Slides/img%20(88).jpg'},
    // {path: 'https://mdbootstrap.com/img/Photos/Slides/img%20(121).jpg'},
    // {path: 'https://mdbootstrap.com/img/Photos/Slides/img%20(31).jpg'},
    // {path: 'https://mdbootstrap.com/img/Photos/Slides/img%20(31).jpg'},
  ]
  isBannerAvailable = false;
  model: any;
  shopList: any = [];

  shortcutModel = [
    { imageUrl: './assets/img/icon.png', title: 'โค้ดส่งฟรี*' },
    { imageUrl: './assets/img/delivery.png', title: 'แบรนด์แท้ถูกชัวร์' },
    { imageUrl: './assets/img/icon.png', title: 'กระเป๋า' },
    { imageUrl: './assets/img/delivery.png', title: 'เสื้อผ้า' },
    { imageUrl: './assets/img/icon.png', title: 'รองเท้า' },
    { imageUrl: './assets/img/delivery.png', title: 'หนังสือ' },
    { imageUrl: './assets/img/icon.png', title: 'เครื่องเขียน' },
    { imageUrl: './assets/img/delivery.png', title: 'โปรโมชั่นพิเศษ' }
  ];

  category1Model = [
    { imageUrl: './assets/img/เสื้อผ้าผู้ชาย.jpeg', title: 'เสื้อผ้าผู้ชาย' },
    { imageUrl: './assets/img/อุปกรณ์การเรียน.png', title: 'อุปกรณ์การเรียน' },
    { imageUrl: './assets/img/อาหารเสริม.jpeg', title: 'อาหารเสริม' },
    { imageUrl: './assets/img/แว่นสายตา.jpg', title: 'แว่นสายตา' },
    { imageUrl: './assets/img/รองเท้าผู้ชาย.jpeg', title: 'รองเท้าผู้ชาย' },
    { imageUrl: './assets/img/คอมพิวเตอร์.jpeg', title: 'คอมพิวเตอร์และแล็ปท็อป' },
    { imageUrl: './assets/img/กล้อง.jpeg', title: 'กล้อง' },
    { imageUrl: './assets/img/กีฬา.jpeg', title: 'กีฬาและกิจกรรมกลางแจ้ง' },
    { imageUrl: './assets/img/icon.png', title: 'สื่อบันเทิง' },
    { imageUrl: './assets/img/เกมเสริมการเรียนรู้.jpeg', title: 'เกมเสริมการเรียนรู้' }
  ];

  category2Model = [
    { imageUrl: './assets/img/เสื้อผ้าผู้หญิง.jpeg', title: 'เสื้อผ้าผู้หญิง' },
    { imageUrl: './assets/img/ความงาม.jpeg', title: 'ความงาม' },
    { imageUrl: './assets/img/ของเล่น.jpeg', title: 'ของเล่น' },
    { imageUrl: './assets/img/เครื่องเขียน.jpeg', title: 'เครื่องเขียน' },
    { imageUrl: './assets/img/กระเป๋า.jpeg', title: 'กระเป๋า' },
    { imageUrl: './assets/img/หนังสือ.jpeg', title: 'หนังสือ' },
    { imageUrl: './assets/img/เครื่องใช้ไฟฟ้า.jpeg', title: 'เครื่องใช้ไฟฟ้า' },
    { imageUrl: './assets/img/เครื่องประดับ.jpeg', title: 'เครื่องประดับ' },
    { imageUrl: './assets/img/อาหารและเครื่องดื่ม.jpeg', title: 'อาหารและเครื่องดิม' },
    { imageUrl: './assets/img/สัตว์เลี้ยง.jpeg', title: 'สัตว์เลี้ยง' }
  ];

  // flashSaleModel = [
  //   { imageUrl: './assets/img/flash_sale_item.png', title: 'โค้ดส่งฟรี*' },
  //   { imageUrl: './assets/img/flash_sale_item.png', title: 'แบรนด์แท้ถูกชัวร์' },
  //   { imageUrl: './assets/img/flash_sale_item.png', title: 'กระเป๋า' },
  //   { imageUrl: './assets/img/flash_sale_item.png', title: 'เสื้อผ้า' },
  //   { imageUrl: './assets/img/flash_sale_item.png', title: 'รองเท้า' },
  //   { imageUrl: './assets/img/flash_sale_item.png', title: 'หนังสือ' }
  // ];

  constructor(
    private serviceProviderService: ServiceProviderService,
    public dialog: MatDialog,
    private activetedRoute: ActivatedRoute,
    private router: Router,
    private deviceService: DeviceDetectorService,
    ) {

    this.readBanner();
    this.readMainPopup();
    this.activetedRoute.queryParams.subscribe(params => {
      let flashSaleModel: any = this.activetedRoute.snapshot.params;
      this.code = params.code;
    });
    this.callReadProduct();
    this.callReadCategoryLv1();
  }

  ngOnInit(): void {
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    if (isMobile) {
      this.router.navigate(['m']);
    }
    else if (isTablet) {
      this.router.navigate(['m']);
    }else{
      this.router.navigate(['']);
    }
    this.subscription = interval(1000).subscribe(x => { this.getTimeDifference(); });
    this.criteriaModelGoods = { skip: 0, limit: 6 }
    this.readFlashSale();
    this.callReadShop();
  }

  onScrollDown() {
    console.log("scrolled down!!");
    this.limit += 10;
    this.callReadProduct();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  readBanner() {
    this.serviceProviderService.post('m/banner/main/read', {}).subscribe(data => {
      this.model = data;

      //วน 2 รอบ ถ้ารูปน้อยจะเป้นสีขาว Bug
      this.model.objectData.forEach(element => {
        this.images.push({ path: element.imageUrl });
      });
      this.model.objectData.forEach(element => {
        this.images.push({ path: element.imageUrl });
      });


      this.isBannerAvailable = true;

      // this.toastr.success('ลบรายการเรียบร้อย', 'แจ้งเตือนระบบ', { timeOut: 2000 });
      // this.messageInput.splice(idx, 1);
    }, err => {
      // this.toastr.error(err.message, 'แจ้งเตือนระบบ', { timeOut: 2000 });
    });
  }

  readMainPopup() {
    this.serviceProviderService.post('m/MainPopup/read', {}).subscribe(data => {
      this.model = data;

      // let model = [];
      // this.model.objectData.forEach(element => {
      //   model.push({ path: element.imageUrl });
      // });
      // this.openMainDialog(this.model.objectData);


      this.isBannerAvailable = true;

      // this.toastr.success('ลบรายการเรียบร้อย', 'แจ้งเตือนระบบ', { timeOut: 2000 });
      // this.messageInput.splice(idx, 1);
    }, err => {
      // this.toastr.error(err.message, 'แจ้งเตือนระบบ', { timeOut: 2000 });
    });
  }

  readFlashSale() {
    this.serviceProviderService.post('m/goods/read', this.criteriaModelGoods).subscribe(data => {
      let flashSaleModel: any = [];
      flashSaleModel = data;
      this.flashSaleModel = flashSaleModel.objectData;
      // console.log('aek');
      // console.log(this.flashSaleModel);
    }, err => {
      // this.spinner.hide();
      // this.toastr.error(err.message, 'แจ้งเตือนระบบ', { timeOut: 2000 });
    });
  }

  openMainDialog(param) {
    param[0].index = 0;
    this.dialog.open(MainDialogComponent, {
      panelClass: 'custom-dialog',
      data: param
    });
  }

  openDownloadDialog() {
    this.dialog.open(DownloadDialogComponent, {
      data: {
        animal: 'panda'
      }
    });
  }

  goToCategory(param) {
    this.router.navigate(['m/category'], { queryParams: { code: param } });
  }

  goToProduct(param: any) {
    this.router.navigate(['m/product'], { queryParams: { code: param } });
  }

  //นับเวลาถอยหลัง
  private subscription: Subscription;
  public dateNow = new Date();
  public dDay = new Date('Jan 31 2022 00:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute = 60;
  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  private getTimeDifference() {
    this.timeDifference = this.dDay.getTime() - new Date().getTime();
    this.allocateTimeUnits(this.timeDifference);
  }

  private allocateTimeUnits(timeDifference) {
    this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
    this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
    this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
    this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  callReadShop() {
    this.serviceProviderService.post('m/shop/read', {}).subscribe(response => {
      var data: any = response;
      this.shopList = data.objectData;
    }, err => {
    });
  }

  navTo(shop) {
    this.router.navigate(['m/shop/' + shop]);
  }

  callReadProduct() {
    this.serviceProviderService.post('m/goods/read', {'limit': this.limit}).subscribe(res => {
      let data: any = {};
      data = res;
      this.listProduct = data.objectData;
      console.log('data =>',data);
      console.log('=>',this.listProduct);
      
    });
  }

  callReadCategoryLv1() {
    this.serviceProviderService.post('m/mCategory/read', {'category': 'lv1'}).subscribe(res => {
      let data: any = {};
      data = res;
      this.listCategoryLv1 = data.objectData;
      console.log('listCategoryLv1 =>',this.listCategoryLv1);
    });
  }
}