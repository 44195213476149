<app-d-t1-header></app-d-t1-header>

<div class="shop-separated mt125">
</div>
<div [hidden]="loading" class="_193wCc">
  <div>
    <div class="tracking-impression-placeholder" style="position: fixed; z-index: 99999; bottom: 0px; left: 0px;"></div>
    <div class="shop-page">
      <div role="main">
        <shop-info [model]="shopInfo"></shop-info>
        <div class="shop-page-menu">
          <div class="navbar-with-more-menu navbar-with-more-menu--narrow" style="background-color: white;">
            <div class="container navbar-with-more-menu__wrapper navbar-with-more-menu__wrapper--no-shadow"
              style="background-color: rgb(255, 255, 255);">
              <div class="navbar-with-more-menu__items">
                <a class="navbar-with-more-menu__item navbar-with-more-menu__item--active"
                  href="/classybkk"><span>หน้าแรก</span></a>
                <a class="navbar-with-more-menu__item" href="/shop/search/{{code}}"><span>สินค้าทั้งหมด</span></a>
                <a class="navbar-with-more-menu__item"
                  *ngFor="let category of categoryList | slice:0:4;let catIndex = index;"
                  (click)="navTo(category)"><span>{{category.title}}</span></a>
                <div [class.open]="isOpenedOtherCategory" (mouseover)="isOpenedOtherCategory = true" (mouseleave)="isOpenedOtherCategory = false"
                  class="navbar-with-more-menu__item navbar-with-more-menu__more">
                  <div class="navbar-with-more-menu__more-label">เพิ่มเติม</div>
                  <svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0" class="shopee-svg-icon icon-down-arrow-filled">
                    <path d="m6.5 12.9-6-7.9s-1.4-1.5.5-1.5h13s1.8 0 .6 1.5l-6 7.9c-.1 0-.9 1.3-2.1 0z"></path>
                  </svg>
                  <ul class="dropdown-menu">
                    <li class="fs1-2" (click)="navTo(category)" *ngFor="let category of categoryList;" (mouseover)="isOpenedOtherCategory = true"
                      (mouseleave)="isOpenedOtherCategory = false">
                      <span class="p0">{{category.title}}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container">
          <div class="shop-page__section shop-page__vouchers" style="background-color: white;">
            <h3 class="shop-page__section-title">โค้ดส่วนลดจากร้านค้า</h3>
            <div class="image-carousel">
              <div class="image-carousel__item-list-wrapper">
                <ul class="image-carousel__item-list" style="width: 100%; transform: translate(0px, 0px);">
                  <li class="image-carousel__item" style="padding: 0px 8px; width: 33%;">
                    <div>
                      <discount></discount>
                    </div>
                  </li>
                  <li class="image-carousel__item" style="padding: 0px 8px; width: 33%;">
                    <div>
                      <discount></discount>
                    </div>
                  </li>
                  <li class="image-carousel__item" style="padding: 0px 8px; width: 33%;">
                    <div>
                      <discount></discount>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="carousel-arrow carousel-arrow--prev carousel-arrow--hidden" role="button" tabindex="0"
                style="opacity: 1; visibility: hidden; transform: translateX(calc(-50% + 0px));"><svg
                  enable-background="new 0 0 13 20" viewBox="0 0 13 20" x="0" y="0"
                  class="shopee-svg-icon icon-arrow-left-bold">
                  <polygon points="4.2 10 12.1 2.1 10 -.1 1 8.9 -.1 10 1 11 10 20 12.1 17.9"></polygon>
                </svg></div>
              <div class="carousel-arrow carousel-arrow--next carousel-arrow--hidden" role="button" tabindex="0"
                style="opacity: 1; visibility: hidden; transform: translateX(calc(50% - 0px));"><svg
                  enable-background="new 0 0 13 21" viewBox="0 0 13 21" x="0" y="0"
                  class="shopee-svg-icon icon-arrow-right-bold">
                  <polygon points="11.1 9.9 2.1 .9 -.1 3.1 7.9 11 -.1 18.9 2.1 21 11.1 12 12.1 11"></polygon>
                </svg></div>
            </div>

          </div>
          <div class="shop-decoration">
            <div class="_2o6h45">
              <!-- new -->
              <div class="shop-collection-view">
                <div class="shopee-header-section _1Y0Eyg shopee-header-section--simple">
                  <div class="shopee-header-section__header">
                    <div class="shopee-header-section__header__title"><svg class="_3ryw2-" fill="none" height="18"
                        viewBox="0 0 14 18" width="14" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="m5.16508 17.5562c-.95125-.5637-1.6325-1.5549-1.67125-2.7212-.05625-2.1788 1.4575-3.2475 2.35375-4.7075 1.2825-2.1.9325-3.4025.9325-3.4025s1.07.6025 1.6925 2.83875c.19376.66125.23375 1.32375.175 1.94625-.09625 1.575-.7775 2.9937-.7775 2.9937s1.18625-.2524 1.51626-2.4112c.54624.5637 1.05126 1.38 1.10876 2.2362.0975 1.4788-.77627 2.8388-2.10002 3.4213 2.29122-.525 3.92872-2.4688 4.49252-3.8888.7187-1.7875.525-3.3824.4075-4.7637-.155-1.885.505-3.28625.505-3.28625s-1.2638.37-2.1975 1.905c-.4275.70125-.6025 1.73-.6025 1.73s.0975-.9125-.5063-2.585c-.60122-1.63375-1.14746-2.21625-1.47871-3.42125-.42626-1.615.52499-3.19.52499-3.19s-3.77.70125-5.4825 3.98625c-1.51625 2.91625-.89375 4.6675-.89375 4.6675s-.6425-.60375-.9725-1.44-.25625-1.59375-.25625-1.59375-2.679996 2.955-1.376246 6.67c.874996 2.605 2.564996 4.3163 4.606246 5.0162z"
                          fill="currentColor"></path>
                      </svg><span class="_1YRfs7">โปรโมชั่นแนะนำ</span></div><a
                      class="shopee-header-section__header-link" href="/shop_hot_deals?shopid=1396470"><button
                        class="shopee-button-no-outline">ดูทั้งหมด&nbsp;<svg enable-background="new 0 0 11 11"
                          viewBox="0 0 11 11" x="0" y="0" class="shopee-svg-icon icon-arrow-right">
                          <path
                            d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z">
                          </path>
                        </svg>
                      </button>
                    </a>
                  </div>
                  <div class="shopee-header-section__content">
                    <div class="row">
                      <product-card class="col-xs-2" *ngFor="let item of promotionList" [model]="item"></product-card>
                    </div>
                  </div>
                </div>
              </div>
              <!-- special -->
              <div class="shop-collection-view">
                <div class="shopee-header-section _1Y0Eyg shopee-header-section--simple">
                  <div class="shopee-header-section__header">
                    <div class="shopee-header-section__header__title"><svg class="_3ryw2-" fill="none" height="18"
                        viewBox="0 0 14 18" width="14" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="m5.16508 17.5562c-.95125-.5637-1.6325-1.5549-1.67125-2.7212-.05625-2.1788 1.4575-3.2475 2.35375-4.7075 1.2825-2.1.9325-3.4025.9325-3.4025s1.07.6025 1.6925 2.83875c.19376.66125.23375 1.32375.175 1.94625-.09625 1.575-.7775 2.9937-.7775 2.9937s1.18625-.2524 1.51626-2.4112c.54624.5637 1.05126 1.38 1.10876 2.2362.0975 1.4788-.77627 2.8388-2.10002 3.4213 2.29122-.525 3.92872-2.4688 4.49252-3.8888.7187-1.7875.525-3.3824.4075-4.7637-.155-1.885.505-3.28625.505-3.28625s-1.2638.37-2.1975 1.905c-.4275.70125-.6025 1.73-.6025 1.73s.0975-.9125-.5063-2.585c-.60122-1.63375-1.14746-2.21625-1.47871-3.42125-.42626-1.615.52499-3.19.52499-3.19s-3.77.70125-5.4825 3.98625c-1.51625 2.91625-.89375 4.6675-.89375 4.6675s-.6425-.60375-.9725-1.44-.25625-1.59375-.25625-1.59375-2.679996 2.955-1.376246 6.67c.874996 2.605 2.564996 4.3163 4.606246 5.0162z"
                          fill="currentColor"></path>
                      </svg><span class="_1YRfs7">โปรโมชั่นแนะนำ</span></div><a
                      class="shopee-header-section__header-link" href="/shop_hot_deals?shopid=1396470"><button
                        class="shopee-button-no-outline">ดูทั้งหมด&nbsp;<svg enable-background="new 0 0 11 11"
                          viewBox="0 0 11 11" x="0" y="0" class="shopee-svg-icon icon-arrow-right">
                          <path
                            d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z">
                          </path>
                        </svg>
                      </button>
                    </a>
                  </div>
                  <div class="shopee-header-section__content">
                    <div class="row">
                      <product-card class="col-xs-2" *ngFor="let item of promotionList" [model]="item"></product-card>
                    </div>
                  </div>
                </div>
              </div>

              <!-- category -->
              <div class="shop-collection-view">
                <div class="shopee-header-section shopee-header-section--simple">
                  <div class="shopee-header-section__header">
                    <div class="shopee-header-section__header__title">
                      <span class="_1YRfs7">หมวดหมู่</span></div><a class="shopee-header-section__header-link"
                      href="/shop_hot_deals?shopid=1396470"><button class="shopee-button-no-outline">ดูทั้งหมด&nbsp;<svg
                          enable-background="new 0 0 11 11" viewBox="0 0 11 11" x="0" y="0"
                          class="shopee-svg-icon icon-arrow-right">
                          <path
                            d="m2.5 11c .1 0 .2 0 .3-.1l6-5c .1-.1.2-.3.2-.4s-.1-.3-.2-.4l-6-5c-.2-.2-.5-.1-.7.1s-.1.5.1.7l5.5 4.6-5.5 4.6c-.2.2-.2.5-.1.7.1.1.3.2.4.2z">
                          </path>
                        </svg>
                      </button>
                    </a>
                  </div>
                  <div class="shopee-header-section__content">
                    <div class="_3HQEO1 row">
                      <product-circle class="col-xs-2-1p" *ngFor="let item of promotionList" [model]="item">
                      </product-circle>
                    </div>
                  </div>
                </div>
              </div>

              <!-- all product -->
              <category-shop [code]="code" [categoryList]="categoryList"></category-shop>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
