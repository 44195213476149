<!-- component header type 1 -->
<app-d-t1-header></app-d-t1-header>
<!--  -->

<div class="container _1QwuCJ mt125">
  <app-d-user [menuActive]="'mypurchase'"></app-d-user>


  <div class="_3D9BVC">
    <div class="_3zmZLR">
      <div></div>
      <div class="iTmqFf">
        <a class="_2s-sXW _228tyk" (click)="goToall()" [ngClass]="{'active': menuSubActive === 'all'}"><span
            class="_2-GXl7">ทั้งหมด</span></a>
        <a class="_2s-sXW" (click)="goTotobepaid()" [ngClass]="{'active': menuSubActive === 's'}"><span
            class="_2-GXl7">ที่ต้องจัดส่ง</span></a>
        <a class="_2s-sXW"><span class="_2-GXl7">ที่ต้องได้รับ</span></a>
        <a class="_2s-sXW"><span class="_2-GXl7">สำเร็จแล้ว</span></a>
        <a class="_2s-sXW"><span class="_2-GXl7">ยกเลิกแล้ว</span></a>
      </div>
      <div></div>
      <div class="_2e9rzB"><svg width="19px" height="19px" viewBox="0 0 19 19">
          <g id="Search-New" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="my-purchase-copy-27" transform="translate(-399.000000, -221.000000)" stroke-width="2">
              <g id="Group-32" transform="translate(400.000000, 222.000000)">
                <circle id="Oval-27" cx="7" cy="7" r="7"></circle>
                <path d="M12,12 L16.9799555,16.919354" id="Path-184" stroke-linecap="round" stroke-linejoin="round">
                </path>
              </g>
            </g>
          </g>
        </svg><input autocomplete="off" placeholder="Search" value=""></div>
      <div>
        <div class="_32cw_C" *ngFor="let s of modelAll;let idxShop = index;">
            <div class="_3wGW0m">
              <div class="_3xIZfd">
                <div class="_1DPpu5"><svg width="17" height="16" viewBox="0 0 17 16" class="_2zTpu5">
                    <path
                      d="M1.95 6.6c.156.804.7 1.867 1.357 1.867.654 0 1.43 0 1.43-.933h.932s0 .933 1.155.933c1.176 0 1.15-.933 1.15-.933h.984s-.027.933 1.148.933c1.157 0 1.15-.933 1.15-.933h.94s0 .933 1.43.933c1.368 0 1.356-1.867 1.356-1.867H1.95zm11.49-4.666H3.493L2.248 5.667h12.437L13.44 1.934zM2.853 14.066h11.22l-.01-4.782c-.148.02-.295.042-.465.042-.7 0-1.436-.324-1.866-.86-.376.53-.88.86-1.622.86-.667 0-1.255-.417-1.64-.86-.39.443-.976.86-1.643.86-.74 0-1.246-.33-1.623-.86-.43.536-1.195.86-1.895.86-.152 0-.297-.02-.436-.05l-.018 4.79zM14.996 12.2v.933L14.984 15H1.94l-.002-1.867V8.84C1.355 8.306 1.003 7.456 1 6.6L2.87 1h11.193l1.866 5.6c0 .943-.225 1.876-.934 2.39v3.21z"
                      stroke-width=".3" stroke="#333" fill="#333" fill-rule="evenodd"></path>
                  </svg>
                  <div class="_1CIbL0">{{s[0].shopName}}</div>
                  <div class="_1q53YG"><button class="stardust-button stardust-button--primary"><svg viewBox="0 0 17 17"
                        class="shopee-svg-icon icon-btn-chat" style="fill: white;">
                        <g fill-rule="evenodd">
                          <path
                            d="M13.89 0C14.504 0 15 .512 15 1.144l-.003.088-.159 2.117.162.001c.79 0 1.46.558 1.618 1.346l.024.15.008.154v9.932a1.15 1.15 0 01-1.779.963l-.107-.08-1.882-1.567-7.962.002a1.653 1.653 0 01-1.587-1.21l-.036-.148-.021-.155-.071-.836h-.01L.91 13.868a.547.547 0 01-.26.124L.556 14a.56.56 0 01-.546-.47L0 13.429V1.144C0 .512.497 0 1.11 0h12.78zM15 4.65l-.259-.001-.461 6.197c-.045.596-.527 1.057-1.106 1.057L4.509 11.9l.058.69.01.08a.35.35 0 00.273.272l.07.007 8.434-.001 1.995 1.662.002-9.574-.003-.079a.35.35 0 00-.274-.3L15 4.65zM13.688 1.3H1.3v10.516l1.413-1.214h10.281l.694-9.302zM4.234 5.234a.8.8 0 011.042-.077l.187.164c.141.111.327.208.552.286.382.131.795.193 1.185.193s.803-.062 1.185-.193c.225-.078.41-.175.552-.286l.187-.164a.8.8 0 011.042 1.209c-.33.33-.753.579-1.26.753A5.211 5.211 0 017.2 7.4a5.211 5.211 0 01-1.706-.28c-.507-.175-.93-.424-1.26-.754a.8.8 0 010-1.132z"
                            fill-rule="nonzero"></path>
                        </g>
                      </svg><span style="margin-left: 5px;">พูดคุย</span></button>
                  </div>
                  <a class="_2L5VLu" href="shop/{{s[0].referenceShopCode}}"><button class="stardust-button"><svg
                        enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0"
                        class="shopee-svg-icon icon-btn-shop">
                        <path
                          d="m15 4.8c-.1-1-.8-2-1.6-2.9-.4-.3-.7-.5-1-.8-.1-.1-.7-.5-.7-.5h-8.5s-1.4 1.4-1.6 1.6c-.4.4-.8 1-1.1 1.4-.1.4-.4.8-.4 1.1-.3 1.4 0 2.3.6 3.3l.3.3v3.5c0 1.5 1.1 2.6 2.6 2.6h8c1.5 0 2.5-1.1 2.5-2.6v-3.7c.1-.1.1-.3.3-.3.4-.8.7-1.7.6-3zm-3 7c0 .4-.1.5-.4.5h-8c-.3 0-.5-.1-.5-.5v-3.1c.3 0 .5-.1.8-.4.1 0 .3-.1.3-.1.4.4 1 .7 1.5.7.7 0 1.2-.1 1.6-.5.5.3 1.1.4 1.6.4.7 0 1.2-.3 1.8-.7.1.1.3.3.5.4.3.1.5.3.8.3zm.5-5.2c0 .1-.4.7-.3.5l-.1.1c-.1 0-.3 0-.4-.1s-.3-.3-.5-.5l-.5-1.1-.5 1.1c-.4.4-.8.7-1.4.7-.5 0-.7 0-1-.5l-.6-1.1-.5 1.1c-.3.5-.6.6-1.1.6-.3 0-.6-.2-.9-.8l-.5-1-.7 1c-.1.3-.3.4-.4.6-.1 0-.3.1-.3.1s-.4-.4-.4-.5c-.4-.5-.5-.9-.4-1.5 0-.1.1-.4.3-.5.3-.5.4-.8.8-1.2.7-.8.8-1 1-1h7s .3.1.8.7c.5.5 1.1 1.2 1.1 1.8-.1.7-.2 1.2-.5 1.5z">
                        </path>
                      </svg><span style="margin-left: 5px;">ดูร้านค้า</span></button>
                  </a>
                </div>

                <div class="_1lE6Rh">
                  <div class="_2hRxNA"><a class="_3nBhmP" href="/user/purchase/order/83313023336324?type=6"><svg
                        enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0"
                        class="shopee-svg-icon icon-free-shipping-line">
                        <g>
                          <line fill="none" stroke-linejoin="round" stroke-miterlimit="10" x1="8.6" x2="4.2" y1="9.8"
                            y2="9.8"></line>
                          <circle cx="3" cy="11.2" fill="none" r="2" stroke-miterlimit="10"></circle>
                          <circle cx="10" cy="11.2" fill="none" r="2" stroke-miterlimit="10"></circle>
                          <line fill="none" stroke-miterlimit="10" x1="10.5" x2="14.4" y1="7.3" y2="7.3"></line>
                          <polyline fill="none" points="1.5 9.8 .5 9.8 .5 1.8 10 1.8 10 9.1" stroke-linejoin="round"
                            stroke-miterlimit="10"></polyline>
                          <polyline fill="none" points="9.9 3.8 14 3.8 14.5 10.2 11.9 10.2" stroke-linejoin="round"
                            stroke-miterlimit="10"></polyline>
                        </g>
                      </svg><span class="_36mfQM"> พัสดุถูกจัดส่งสำเร็จแล้ว</span>
                    </a>
                  </div>
                  <div class="clakWe">สำเร็จแล้ว</div>
                </div>
              </div>
              <div class="_39XDzv"></div>
              <div class="_2lVoQ1" *ngFor="let item of s[0].items; let idxItem = index;">
                <div class="_1limL3">
                  <div>
                      <span class="_1BJEKe">
                      <div class="_3huAcN">
                        <div class="_3btL3m">
                          <div class="shopee-image__wrapper">
                            <div class="shopee-image__place-holder"><svg enable-background="new 0 0 15 15"
                                viewBox="0 0 15 15" x="0" y="0" class="shopee-svg-icon icon-loading-image">
                                <g>
                                  <rect fill="none" height="8" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-miterlimit="10" width="10" x="1" y="4.5"></rect>
                                  <line fill="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-miterlimit="10" x1="1" x2="11" y1="6.5" y2="6.5"></line>
                                  <rect fill="none" height="3" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-miterlimit="10" width="3" x="11" y="6.5"></rect>
                                  <line fill="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-miterlimit="10" x1="1" x2="11" y1="14.5" y2="14.5"></line>
                                  <line fill="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-miterlimit="10" x1="6" x2="6" y1=".5" y2="3"></line>
                                  <line fill="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-miterlimit="10" x1="3.5" x2="3.5" y1="1" y2="3"></line>
                                  <line fill="none" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-miterlimit="10" x1="8.5" x2="8.5" y1="1" y2="3"></line>
                                </g>
                              </svg></div>
                            <div class="shopee-image__content"
                              style="background-image: url(&quot;{{item.imageUrl}}&quot;);">
                              <div class="shopee-image__content--blur"> </div>
                            </div>
                          </div>
                        </div>
                        <div class="_1cxKtp">
                          <div>
                            <div class="_1xHDVY"><span class="_30COVM">{{item.goodsTitle}}</span></div>
                          </div>
                          <div>
                            <div class="y8ewrc">ตัวเลือกสินค้า: {{item.title}}</div>
                            <div class="_2H6lAy">x{{item.qty}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="_1kvNGb">
                        <div><span class="_34DOjq">฿{{item.price}}</span><span class="mBERmM _2mEJ4q">฿{{item.netPrice}}</span></div>
                      </div>
                    </span>
                </div>
                <div *ngIf="(idxItem != s[0].items.length - 1)" class="_39XDzv MgTo"></div>

                </div>
              </div>
              <div class="g7tA3o"></div>
            </div>
          <div class="_1J7vLy">
            <div class="DI-rNr tyOBoQ"> </div>
            <div class="DI-rNr _25igL4"> </div>
          </div>
          <div class="_39ypT2">
            <div class="h0Jexl"><span class="_3HcfDh">
                <div class="_3Hhx3p"><svg width="16" height="17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M15.94 1.664s.492 5.81-1.35 9.548c0 0-.786 1.42-1.948 2.322 0 0-1.644 1.256-4.642 2.561V0s2.892 1.813 7.94 1.664zm-15.88 0C5.107 1.813 8 0 8 0v16.095c-2.998-1.305-4.642-2.56-4.642-2.56-1.162-.903-1.947-2.323-1.947-2.323C-.432 7.474.059 1.664.059 1.664z"
                      fill="url(#paint0_linear)"></path>
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M8.073 6.905s-1.09-.414-.735-1.293c0 0 .255-.633 1.06-.348l4.84 2.55c.374-2.013.286-4.009.286-4.009-3.514.093-5.527-1.21-5.527-1.21s-2.01 1.306-5.521 1.213c0 0-.06 1.352.127 2.955l5.023 2.59s1.09.42.693 1.213c0 0-.285.572-1.09.28L2.928 8.593c.126.502.285.99.488 1.43 0 0 .456.922 1.233 1.56 0 0 1.264 1.126 3.348 1.941 2.087-.813 3.352-1.963 3.352-1.963.785-.66 1.235-1.556 1.235-1.556a6.99 6.99 0 00.252-.632L8.073 6.905z"
                      fill="#FEFEFE"></path>
                    <defs>
                      <linearGradient id="paint0_linear" x1="8" y1="0" x2="8" y2="16.095"
                        gradientUnits="userSpaceOnUse">
                        <stop stop-color="#F53D2D"></stop>
                        <stop offset="1" stop-color="#F63"></stop>
                      </linearGradient>
                    </defs>
                  </svg></div>
              </span>
              <div class="_3LfHXA">ยอดคำสั่งซื้อทั้งหมด:</div>
              <div class="isoXOF">฿{{priceShop(s[0].items)}}</div>
            </div>
          </div>
          <div class="_2GvoV8">
            <div class="_1YuAW0"><span class="RJy7fe">ให้คะแนนสินค้าภายใน<div class="shopee-drawer"><u
                    class="_1-dXW-">07-08-2021</u></div></span><span class="_3YDKgY">รีวิวเลยตอนนี้
                เพื่อรับ 0.5 Shopee coins</span></div>
            <div class="sCoiFR">
              <div *ngIf="s[0].items[0].status == 'V'" class="EoE22E"><button class="stardust-button stardust-button--primary _3iADE8" (click)="goTo('qr-payment',s[0].orderNoReference)">จ่ายเงิน</button>
              </div>
              <div *ngIf="s[0].items[0].status != 'V'" class="EoE22E"><button class="stardust-button stardust-button--primary _3iADE8">ให้คะแนน</button>
              </div>
              <div class="Vt1J92"><button
                  class="stardust-button stardust-button--secondary _3iADE8">ติดต่อผู้ขาย</button>
              </div>
              <div class="Vt1J92"><button
                  class="stardust-button stardust-button--secondary _3iADE8">ซื้ออีกครั้ง</button>
              </div>
            </div>
          </div>
        </div>
        <!--  -->
        <div *ngIf="menuSubActive === 's'">การซื้อของฉัน</div>
      </div>
    </div>
  </div>

</div>
