import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FacebookService } from 'src/app/shares/facebook.service';
import { GoogleFirebaseService } from 'src/app/shares/google-firebase.service';
import { Utilities } from 'src/app/shares/utilities';

@Component({
  selector: 'app-d-t1-header',
  templateUrl: './d-t1-header.component.html',
  styleUrls: ['./d-t1-header.component.css']
})
export class DT1HeaderComponent implements OnInit {
  userData: any = { profileCode: '' };
  isOpenedDownload = false;
  isOpenedNotification = false;
  isOpenedCart = false;

  constructor(
    private router: Router,
    private utilities: Utilities,
    public googleFirebaseService: GoogleFirebaseService,
    public facebookService: FacebookService,
  ) { }

  ngOnInit(): void {

    if (this.utilities.getUserLocalStorage().profileCode != '' && this.utilities.getUserLocalStorage().profileCode != null) {
      this.userData = this.utilities.getUserLocalStorage();
    }
  }

  openFacebook() {
    window.open('https://www.facebook.com/profile.php?id=100050175092653', "_blank");
  }

  goToNotification() {
    this.router.navigate(['notification']);
  }

  goToLogin() {
    this.router.navigate(['login']);
  }

  goToProfile() {
    this.router.navigate(['profile']);
  }

  goToRegister() {
    this.router.navigate(['register']);
  }

  goToCart() {
    this.router.navigate(['cart']);
  }

  goToMain() {
    this.router.navigate(['']);
  }

  logout() {
    if (this.userData.type == "google")
      this.googleFirebaseService.signOut();
    else if (this.userData.type == "facebook")
      this.facebookService.signOut();
    localStorage.clear();
    this.userData.profileCode = '';
  }

}
