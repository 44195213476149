import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from "@angular/common/http";

import { AppRoutingModule } from './app-routing.module';
import { PopoverModule } from 'ngx-smart-popover';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { DHomeComponent } from './pages/home/d-home/d-home.component';
import { MHomeComponent } from './pages/home/m-home/m-home.component';
import { DownloadDialogComponent } from './popups/download-dialog/download-dialog.component';
import { DProductComponent } from './pages/product/d-product/d-product.component';
import { MProductComponent } from './pages/product/m-product/m-product.component';
import { DT1HeaderComponent } from './headers/d-t1-header/d-t1-header.component';
import { MT1HeaderComponent } from './headers/m-t1-header/m-t1-header.component';
import { MainDialogComponent } from './popups/main-dialog/main-dialog.component';
import { DNotificationComponent } from './pages/notification/d-notification/d-notification.component';
import { MNotificationComponent } from './pages/notification/m-notification/m-notification.component';
import { DMypurchaseComponent } from './pages/mypurchase/d-mypurchase/d-mypurchase.component';
import { MMypurchaseComponent } from './pages/mypurchase/m-mypurchase/m-mypurchase.component';
import { DLoginComponent } from './pages/login/d-login/d-login.component';
import { MLoginComponent } from './pages/login/m-login/m-login.component';
import { MRegisterComponent } from './pages/register/m-register/m-register.component';
import { DRegisterComponent } from './pages/register/d-register/d-register.component';
import { DCartComponent } from './pages/cart/d-cart/d-cart.component';
import { MCartComponent } from './pages/cart/m-cart/m-cart.component';
import { DCategoryContentComponent } from './pages/category-content/d-category-content/d-category-content.component';
import { MCategoryContentComponent } from './pages/category-content/m-category-content/m-category-content.component';
import { DPaymentSuccessComponent } from './pages/payment-success/d-payment-success/d-payment-success.component';
import { MPaymentSuccessComponent } from './pages/payment-success/m-payment-success/m-payment-success.component';
import { DConfirmOrderComponent } from './pages/confirm-order/d-confirm-order/d-confirm-order.component';
import { MConfirmOrderComponent } from './pages/confirm-order/m-confirm-order/m-confirm-order.component';
import { DProfileComponent } from './pages/profile/d-profile/d-profile.component';
import { MProfileComponent } from './pages/profile/m-profile/m-profile.component';
import { DPaymentComponent } from './pages/payment/d-payment/d-payment.component';
import { MPaymentComponent } from './pages/payment/m-payment/m-payment.component';
import { DUserComponent } from './pages/user/d-user/d-user.component';
import { MUserComponent } from './pages/user/m-user/m-user.component';
import { DAddressComponent } from './pages/address/d-address/d-address.component';
import { MAddressComponent } from './pages/address/m-address/m-address.component';
import { DPasswordComponent } from './pages/password/d-password/d-password.component';
import { MPasswordComponent } from './pages/password/m-password/m-password.component';

import { AddressComponent } from './popups/address/address.component';
import { FormsModule } from '@angular/forms';
import { DShopComponent } from './pages/shop/d-shop/d-shop.component';
import { MShopComponent } from './pages/shop/m-shop/m-shop.component';
import { ProductCardComponent } from './component/product-card/product-card.component';
import { ShopInfoComponent } from './component/shop-info/shop-info.component';
import { DiscountComponent } from './component/discount/discount.component';
import { ProductCircleComponent } from './component/product-circle/product-circle.component';
import { DSearchComponent } from './pages/search/d-search/d-search.component';
import { MSearchComponent } from './pages/search/m-search/m-search.component';
import { CategoryShopComponent } from './component/category-shop/category-shop.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DAllShopsComponent } from './pages/all-shops/d-all-shops/d-all-shops.component';
import { MAllShopsComponent } from './pages/all-shops/m-all-shops/m-all-shops.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { DQrPaymentComponent } from './pages/qr-payment/d-qr-payment/d-qr-payment.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CommonModule } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { GoodsShopCardComponent } from './component/goodsshop-card/goodsshop-card.component';
import { DateFormatPipe } from './date-format.pipe';
import { DatetimeFormatPipe } from './datetime-format.pipe';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgxGalleryModule } from '@kolkov/ngx-gallery';
import { GoodsSellGoodComponent } from './component/goodssellgood-card/goodssellgood-card.component';
import { ShopAllCardComponent } from './component/shopall-card/shopall-card.component';

var config = {
  apiKey: "AIzaSyANRhtzfr5853RYYr-4VrmTKFT3dJBtF1c",
  authDomain: "we-mart-324106.firebaseapp.com",
  projectId: "we-mart-324106",
  storageBucket: "we-mart-324106.appspot.com",
  messagingSenderId: "844740746751",
  appId: "1:844740746751:web:703233c0cad54d50d81bca",
  measurementId: "G-B348DXZWCQ"
};
@NgModule({
  declarations: [
    AppComponent,
    DHomeComponent,
    MHomeComponent,
    DownloadDialogComponent,
    DProductComponent,
    MProductComponent,
    DT1HeaderComponent,
    MT1HeaderComponent,
    MainDialogComponent,
    DNotificationComponent,
    MNotificationComponent,
    DMypurchaseComponent,
    MMypurchaseComponent,
    DLoginComponent,
    MLoginComponent,
    MRegisterComponent,
    DRegisterComponent,
    DCartComponent,
    MCartComponent,
    DCategoryContentComponent,
    MCategoryContentComponent,
    DPaymentSuccessComponent,
    MPaymentSuccessComponent,
    DConfirmOrderComponent,
    MConfirmOrderComponent,
    DProfileComponent,
    MProfileComponent,
    DPaymentComponent,
    MPaymentComponent,
    DUserComponent,
    MUserComponent,
    DAddressComponent,
    MAddressComponent,
    DPasswordComponent,
    MPasswordComponent,
    AddressComponent,
    DShopComponent,
    MShopComponent,
    ProductCardComponent,
    ShopInfoComponent,
    DiscountComponent,
    ProductCircleComponent,
    DSearchComponent,
    MSearchComponent,
    CategoryShopComponent,
    DAllShopsComponent,
    MAllShopsComponent,
    DQrPaymentComponent,
    GoodsShopCardComponent,
    DateFormatPipe,
    DatetimeFormatPipe,
    GoodsSellGoodComponent,
    ShopAllCardComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    PopoverModule,
    IvyCarouselModule,
    MatDialogModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    MatButtonToggleModule,
    NgxQRCodeModule,
    InfiniteScrollModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(config),
    MatMomentDateModule, 
    NgxGalleryModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
