<!-- <div class="shop-search-result-view__item col-xs-2-4"><a data-sqe="link"
    href="/SKMEI-นาฬิกาข้อมือผู้ชายดิจิตอล-ของแท้-100-พร้อมกล่องและใบครบเซ็ต-รุ่น-SK-1251-สไตล์สปอร์ต-i.1396470.421959875?position=0">

  </a></div> -->

<div class="_35LNwy" (click)="selected(model.code)">
  <div class="fBhek2">
    <div class="_25_r8I _2SHkSu">
      <img width="invalid-value" height="invalid-value" alt="{{model.title}}" class="mxM4vG _2GchKS"
          style="object-fit: contain" src="{{model.imageUrl}}">
      <div *ngIf="model.recommendedShop" class="_1qt0vU">
        <div class="Oi0pcf _3Bekkv" style="background-color: rgb(208, 1, 27);">
          <div class="m7KwcV">ร้านค้าแนะนำ</div>
        </div>
      </div>
      <div *ngIf="model.discount != 0" class="_1jPz8l">
        <div class="_3iRehQ _36vTKx _2xY8te shopee-badge">
          <div class="_2TDZGE _26VpFE">
            <span class="percent">{{model.discount}} {{model.disCountUnit == 'P' ? '%' : 'บาท'}}</span>
            <span class="_17XqBU">ลด</span>
          </div>
        </div>
      </div>
      <!-- <div class="_1jowzv">
          <div class="customized-overlay-image"><img
              src="https://cf.shopee.co.th/file/c0c75488a1e3e5990af66eef37c7d8f3"></div>
        </div> -->
    </div>
    <div class="_1ObP5d">
      <div class="_1nHzH4" data-sqe="name">
        <div class="PFM7lj">
          <div class="yQmmFK _1POlWt _36CEnF">{{model.title}}</div>
        </div>
        <div class="_2NfTth">
          <div class="_1PWkR nt-medium nt-foot _3nkRL" style="color: rgb(246, 145, 19);"><svg class="_2DRZW _2xFcL"
              viewBox="-0.5 -0.5 4 16">
              <path
                d="M4 0h-3q-1 0 -1 1a1.2 1.5 0 0 1 0 3v0.333a1.2 1.5 0 0 1 0 3v0.333a1.2 1.5 0 0 1 0 3v0.333a1.2 1.5 0 0 1 0 3q0 1 1 1h3"
                stroke-width="1" transform="" stroke="currentColor" fill="#f69113"></path>
            </svg>
            <div class="_1FKkT _3Ao0A" style="color: white; background-color: rgb(246, 145, 19);">ส่วนลด 5%</div>
            <svg class="_2DRZW _2xFcL" viewBox="-0.5 -0.5 4 16">
              <path
                d="M4 0h-3q-1 0 -1 1a1.2 1.5 0 0 1 0 3v0.333a1.2 1.5 0 0 1 0 3v0.333a1.2 1.5 0 0 1 0 3v0.333a1.2 1.5 0 0 1 0 3q0 1 1 1h3"
                stroke-width="1" transform="rotate(180) translate(-3 -15)" stroke="currentColor" fill="#f69113">
              </path>
            </svg>
          </div>
        </div>
      </div>
      <div class="_32hnQt">
        <div *ngIf="model.discount != 0" class="WTFwws qs4le9 _3_-SiN">฿{{formatPrice(model.price)}}</div>
        <div class="WTFwws _1k2Ulw _5W0f35" style="max-width: calc(100% - 21.9965px);">
          <span class="v1dDF-">฿</span>
          <span class="_24JoLh">{{formatPrice(model.netPrice)}}</span>
        </div>
        <div class="_1HW7eF">
          <svg height="12" viewBox="0 0 20 12" width="20" class="shopee-svg-icon icon-free-shipping">
            <g fill="none" fill-rule="evenodd" transform="">
              <rect fill="#00bfa5" fill-rule="evenodd" height="9" rx="1" width="12" x="4"></rect>
              <rect height="8" rx="1" stroke="#00bfa5" width="11" x="4.5" y=".5"></rect>
              <rect fill="#00bfa5" fill-rule="evenodd" height="7" rx="1" width="7" x="13" y="2"></rect>
              <rect height="6" rx="1" stroke="#00bfa5" width="6" x="13.5" y="2.5"></rect>
              <circle cx="8" cy="10" fill="#00bfa5" r="2"></circle>
              <circle cx="15" cy="10" fill="#00bfa5" r="2"></circle>
              <path
                d="m6.7082481 6.7999878h-.7082481v-4.2275391h2.8488017v.5976563h-2.1405536v1.2978515h1.9603297v.5800782h-1.9603297zm2.6762505 0v-3.1904297h.6544972v.4892578h.0505892c.0980164-.3134765.4774351-.5419922.9264138-.5419922.0980165 0 .2276512.0087891.3003731.0263672v.6210938c-.053751-.0175782-.2624312-.038086-.3762568-.038086-.5122152 0-.8758247.3017578-.8758247.75v1.8837891zm3.608988-2.7158203c-.5027297 0-.8536919.328125-.8916338.8261719h1.7390022c-.0158092-.5009766-.3446386-.8261719-.8473684-.8261719zm.8442065 1.8544922h.6544972c-.1549293.571289-.7050863.9228515-1.49238.9228515-.9864885 0-1.5903965-.6269531-1.5903965-1.6464843 0-1.0195313.6165553-1.6669922 1.5872347-1.6669922.9580321 0 1.5366455.6064453 1.5366455 1.6083984v.2197266h-2.4314412v.0351562c.0221328.5595703.373095.9140625.9169284.9140625.4110369 0 .6924391-.1376953.8189119-.3867187zm2.6224996-1.8544922c-.5027297 0-.853692.328125-.8916339.8261719h1.7390022c-.0158091-.5009766-.3446386-.8261719-.8473683-.8261719zm.8442064 1.8544922h.6544972c-.1549293.571289-.7050863.9228515-1.49238.9228515-.9864885 0-1.5903965-.6269531-1.5903965-1.6464843 0-1.0195313.6165553-1.6669922 1.5872347-1.6669922.9580321 0 1.5366455.6064453 1.5366455 1.6083984v.2197266h-2.4314412v.0351562c.0221328.5595703.373095.9140625.9169284.9140625.4110369 0 .6924391-.1376953.8189119-.3867187z"
                fill="#fff"></path>
              <path d="m .5 8.5h3.5v1h-3.5z" fill="#00bfa5"></path>
              <path d="m0 10.15674h3.5v1h-3.5z" fill="#00bfa5"></path>
              <circle cx="8" cy="10" fill="#047565" r="1"></circle>
              <circle cx="15" cy="10" fill="#047565" r="1"></circle>
            </g>
          </svg>
        </div>
      </div>
      <div class="beMRch">
        <div class="_3nzERx"><svg height="16" viewBox="0 0 16 16" width="16" version="1.1">
            <path
              d="m7.251221 4.2145388c-.549143-.4552525-1.2488781-.7145388-1.951221-.7145388-1.5719593 0-2.8 1.2269253-2.8 2.7970027 0 .5878515.158291 1.1598348.483492 1.7618948.6414654 1.1875754 1.5644044 2.1358244 4.4829309 4.7799304l.5348542.4864596.5326254-.4807607c2.9306205-2.660747 3.8471674-3.6039919 4.486777-4.7931984.3223805-.5993922.4793205-1.1689848.4793205-1.7543257 0-1.5700774-1.2280407-2.7970027-2.8-2.7970027-.7029148 0-1.4032175.2597087-1.9497845.7133288l-.0367779.0309601c-.1203966.1029087-.2318185.2143106-.3329071.3329122l-.3805305.4464557-.3805305-.4464557c-.1010886-.1186016-.2125105-.2300035-.3301434-.3305672z"
              fill="none" stroke="#000" stroke-opacity=".54"></path>
          </svg></div>
        <div class="_3dC36C" data-sqe="rating">
          <div class="shopee-rating-stars">
            <div class="shopee-rating-stars__stars">
              <div class="shopee-rating-stars__star-wrapper">
                <div class="shopee-rating-stars__lit" style="width: 100%;"><svg enable-background="new 0 0 15 15"
                    viewBox="0 0 15 15" x="0" y="0"
                    class="shopee-svg-icon shopee-rating-stars__gold-star icon-rating-solid">
                    <polygon
                      points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                      stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon>
                  </svg></div><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0"
                  class="shopee-svg-icon shopee-rating-stars__dark-star icon-rating-solid">
                  <polygon points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                    stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon>
                </svg>
              </div>
              <div class="shopee-rating-stars__star-wrapper">
                <div class="shopee-rating-stars__lit" style="width: 100%;"><svg enable-background="new 0 0 15 15"
                    viewBox="0 0 15 15" x="0" y="0"
                    class="shopee-svg-icon shopee-rating-stars__gold-star icon-rating-solid">
                    <polygon
                      points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                      stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon>
                  </svg></div><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0"
                  class="shopee-svg-icon shopee-rating-stars__dark-star icon-rating-solid">
                  <polygon points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                    stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon>
                </svg>
              </div>
              <div class="shopee-rating-stars__star-wrapper">
                <div class="shopee-rating-stars__lit" style="width: 100%;"><svg enable-background="new 0 0 15 15"
                    viewBox="0 0 15 15" x="0" y="0"
                    class="shopee-svg-icon shopee-rating-stars__gold-star icon-rating-solid">
                    <polygon
                      points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                      stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon>
                  </svg></div><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0"
                  class="shopee-svg-icon shopee-rating-stars__dark-star icon-rating-solid">
                  <polygon points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                    stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon>
                </svg>
              </div>
              <div class="shopee-rating-stars__star-wrapper">
                <div class="shopee-rating-stars__lit" style="width: 100%;"><svg enable-background="new 0 0 15 15"
                    viewBox="0 0 15 15" x="0" y="0"
                    class="shopee-svg-icon shopee-rating-stars__gold-star icon-rating-solid">
                    <polygon
                      points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                      stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon>
                  </svg></div><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0"
                  class="shopee-svg-icon shopee-rating-stars__dark-star icon-rating-solid">
                  <polygon points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                    stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon>
                </svg>
              </div>
              <div class="shopee-rating-stars__star-wrapper">
                <div class="shopee-rating-stars__lit" style="width: 79.2539%;"><svg enable-background="new 0 0 15 15"
                    viewBox="0 0 15 15" x="0" y="0"
                    class="shopee-svg-icon shopee-rating-stars__gold-star icon-rating-solid">
                    <polygon
                      points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                      stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon>
                  </svg></div><svg enable-background="new 0 0 15 15" viewBox="0 0 15 15" x="0" y="0"
                  class="shopee-svg-icon shopee-rating-stars__dark-star icon-rating-solid">
                  <polygon points="7.5 .8 9.7 5.4 14.5 5.9 10.7 9.1 11.8 14.2 7.5 11.6 3.2 14.2 4.3 9.1 .5 5.9 5.3 5.4"
                    stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10"></polygon>
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div class="go5yPW">ขายแล้ว 41.4พัน ชิ้น</div>
      </div>
      <div class="_2CWevj">จังหวัดกรุงเทพมหานคร</div>
    </div>
  </div>
</div>
