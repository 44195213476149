import { Component, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Utilities } from 'src/app/shares/utilities';

@Component({
  selector: 'app-d-user',
  templateUrl: './d-user.component.html',
  styleUrls: ['./d-user.component.css']
})
export class DUserComponent implements OnInit {
  @Input() menuActive: String = '';
  menuSubActive = 'all';
  userData:any = {};

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target)) {
      // inside
    } else {
      // outside
    }
  }

  constructor(
    private router: Router,
    private eRef: ElementRef,
    private utilities: Utilities
  ) { }

  ngOnInit(): void {
    this.userData = this.utilities.getUserLocalStorage();
    console.log(this.userData);
    
  }

  navTo(param) {
    switch (param) {
      case 'notification':
        this.router.navigate(['notification']);
        break;
      case 'contactHelp':
        this.router.navigate(['']);
        break;
      case 'register':
        this.router.navigate(['register']);
        break;
      case 'confirm-order':
        this.menuActive = 'confirm-order';
        this.router.navigate(['confirm-order']);
        break;
      case 'profile':
        this.menuActive = 'profile';
        this.router.navigate(['profile']);
        break;
      case 'payment':
        this.menuActive = 'payment';
        this.router.navigate(['payment']);
        break;
      case 'address':
        this.menuActive = 'address';
        this.router.navigate(['address']);
        break;
      case 'password':
        this.menuActive = 'password';
        this.router.navigate(['password']);
        break;
      case 'mypurchase':
        this.menuActive = 'mypurchase';
        this.router.navigate(['mypurchase']);
        break;

      default:
        this.router.navigate(['']);
        break;
    }
  }

  checkThisAccount() {
    if (this.menuActive == 'profile' ||
      this.menuActive == 'password' ||
      this.menuActive == 'address' ||
      this.menuActive == 'payment'
    ) {
      return true;
    } else
      return false;
  }

  setPage(page: String) {
    this.menuActive = page;
  }
}
