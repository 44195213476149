<!-- component header type 1 -->
<app-d-t1-header></app-d-t1-header>
<!--  -->

<div class="container _1QwuCJ mt125">
  <app-d-user [menuActive]="'payment'"></app-d-user>

  <div class="_3D9BVC">
    <div class="h4QDlo" role="main">
      <div>
        <div class="my-account-section">
          <div class="my-account-section__header">
            <div class="my-account-section__header-left">
              <div class="my-account-section__header-title">บัตรเครดิต/เดบิตของฉัน</div>
              <div class="my-account-section__header-subtitle"></div>
            </div>
            <div class="my-account-section__header-button"><button
                class="shopee-button-solid shopee-button-solid--bacc-tab ">
                <div class="button-with-icon">
                  <div class="button-with-icon__icon"><svg enable-background="new 0 0 10 10" viewBox="0 0 10 10" x="0"
                      y="0" class="shopee-svg-icon icon-plus-sign">
                      <polygon
                        points="10 4.5 5.5 4.5 5.5 0 4.5 0 4.5 4.5 0 4.5 0 5.5 4.5 5.5 4.5 10 5.5 10 5.5 5.5 10 5.5">
                      </polygon>
                    </svg></div>
                  <div>เพิ่มบัตรเครดิต/บัตรเดบิต</div>
                </div>
              </button></div>
          </div>
          <div class="creditcard-card">
            <div class="creditcard-card__logo"><img alt="บัตรเครดิตในเครือกรุงศรี"
                src="https://deo.shopeemobile.com/shopee/shopee-pcmall-live-sg/assets/037611acbdb4a7c5a2363ca313c57bef.png">
            </div>
            <div class="creditcard-card__type">VISA</div>
            <div class="bacc-default-badge">ค่าเริ่มต้น</div>
            <div class="creditcard-card__spacer"></div>
            <div class="_11C6dM ">**** **** **** 1561</div>
            <div class="creditcard-card__actions"><button class="bacc-secondary-action-btn">ลบ</button><button
                type="button" class="btn btn-light btn--s btn--inline btn-light--disabled"
                aria-disabled="true">ตั้งเป็นค่าตั้งต้น</button></div>
          </div>
        </div>
        <div class="my-account-section">
          <div class="my-account-section__header">
            <div class="my-account-section__header-left">
              <div class="my-account-section__header-title">บัญชีธนาคารของฉัน</div>
              <div class="my-account-section__header-subtitle"></div>
            </div>
            <div class="my-account-section__header-button"><button
                class="shopee-button-solid shopee-button-solid--primary shopee-button-solid--bacc-tab ">
                <div class="button-with-icon">
                  <div class="button-with-icon__icon"><svg enable-background="new 0 0 10 10" viewBox="0 0 10 10" x="0"
                      y="0" class="shopee-svg-icon icon-plus-sign">
                      <polygon
                        points="10 4.5 5.5 4.5 5.5 0 4.5 0 4.5 4.5 0 4.5 0 5.5 4.5 5.5 4.5 10 5.5 10 5.5 5.5 10 5.5">
                      </polygon>
                    </svg></div>
                  <div>เพิ่มบัญชีธนาคาร</div>
                </div>
              </button></div>
          </div>
          <div class="bacc-centered-msg">คุณยังไม่มีบัญชีธนาคาร</div>
        </div>
        <div></div>
      </div>
    </div>
  </div>
</div>
