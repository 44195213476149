import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-address',
  templateUrl: './m-address.component.html',
  styleUrls: ['./m-address.component.css']
})
export class MAddressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
