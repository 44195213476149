import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-mypurchase',
  templateUrl: './m-mypurchase.component.html',
  styleUrls: ['./m-mypurchase.component.css']
})
export class MMypurchaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
