<!-- component header type 1 -->
<app-d-t1-header></app-d-t1-header>

<!-- banner -->
<div class="col-md-12 pt-5 bg-white mt125">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-7 p-0 zidx1">
            <carousel *ngIf="isBannerAvailable" [images]="images" [height]="240" [cellWidth]="'100%'" [dots]="true" [arrows]="false" [autoplay]="true" [loop]="true"></carousel>
        </div>
        <div class="col-md-3 p-0 pd2">
            <div>
                <img class="d-block w-100 " src="{{images[1].path}}" height="119">
            </div>
            <div class="pt-1">
                <img class="d-block w-100 " src="{{images[2].path}}" height="118">
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- shortcut menu -->
<div class="col-md-12 bg-white">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10">
            <div class="row pb-5" *ngIf="ShopShowAll === 'true'">
                <table class="w200p">
                    <tr>
                        <td class="pointer td1 w12-5p" *ngFor="let item of shopList | slice:0:8;">
                            <div class="img-box" style="background-image: url(&quot;{{item.imageUrl}}&quot;);" (click)="navTo(item.code)"></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pointer td1 w12-5p" *ngFor="let item of shopList| slice:0:8;" style="vertical-align:middle;">
                            <div class="pt-2" (click)="navTo(item.code)">{{item.title}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pointer td1 w12-5p" *ngFor="let item of shopList | slice:8:15;">
                            <div class="img-box" style="background-image: url(&quot;{{item.imageUrl}}&quot;);" (click)="navTo(item.code)"></div>
                        </td>
                        <td class="pointer td1 w12-5p">
                            <div class="img-box" style="background-image: url(&quot;../assets/img/Group6910@2x.png&quot;);" (click)="navToAllShop()"></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pointer td1 w12-5p" *ngFor="let item of shopList| slice:8:15;" style="vertical-align:middle;">
                            <div class="pt-2" (click)="navTo(item.code)">{{item.title}}</div>
                        </td>
                        <td class="pointer td1 w12-5p" style="vertical-align:middle;">
                            <div class="pt-2" (click)="navToAllShop()">ดูทั้งหมด</div>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="row pb-5" *ngIf="ShopShowAll === 'false'">
                <table class="w200p">
                    <tr>
                        <td class="pointer td1 w12-5p" *ngFor="let item of shopList | slice:0:8;">
                            <div class="img-box" style="background-image: url(&quot;{{item.imageUrl}}&quot;);" (click)="navTo(item.code)"></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pointer td1 w12-5p" *ngFor="let item of shopList| slice:0:8;" style="vertical-align:middle;">
                            <div class="pt-2" (click)="navTo(item.code)">{{item.title}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pointer td1 w12-5p" *ngFor="let item of shopList | slice:8:16;">
                            <div class="img-box" style="background-image: url(&quot;{{item.imageUrl}}&quot;);" (click)="navTo(item.code)"></div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pointer td1 w12-5p" *ngFor="let item of shopList| slice:8:16;" style="vertical-align:middle;">
                            <div class="pt-2" (click)="navTo(item.code)">{{item.title}}</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- banner download -->
<div class="col-md-12 p-4">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 pointer text-center p-0" (click)="openDownloadDialog()">
            <img style="width:100%" src="./assets/img/banner_download_wm.png">
            <!-- <div class="h100p"> -->
            <!-- <br><br> -->
            <!-- <h1>ดาวน์โหลดแอป SUKSAPAN เพื่อรับส่วนลดหรือสินค้าฟรี</h1> -->
            <!-- <img style="width:100%" src="./assets/img/banner_download.png"> -->
            <!-- </div> -->
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- หมวดหมู่ -->
<div class="col-md-12 p-4 pt-2">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 pt-4 bg-white scroll">
            <h3>&nbsp;&nbsp;&nbsp;&nbsp;หมวดหมู่</h3>
            <div class="row pt-4">
                <table class="w100p">
                    <tr>
                        <td class="pointer td2 w10p" *ngFor="let item of listCategoryLv1 | slice:0:10;" (click)="goToCategory(item.code)">
                            <img class="rcorners1" [src]="item.imageUrl" width="70" height="70">
                            <div class="p-3">{{item.title}}</div>
                        </td>
                    </tr>
                    <tr>
                        <td class="pointer td2 w10p" *ngFor="let item of  listCategoryLv1 | slice:10:20;" (click)="goToCategory(item.code)">
                            <img class="rcorners1" [src]="item.imageUrl" width="70" height="70">
                            <div class="p-3">{{item.title}}</div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- flash sale -->
<div class="col-md-12 p-4 pt-2">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 pt-4 bg-white">

            <div>
                &nbsp;&nbsp;&nbsp;&nbsp;<img src="./assets/img/flash_sale.gif" width="150"><span class="fs1-5">&nbsp;&nbsp;&nbsp;&nbsp;<b>{{daysToDday}} วัน {{hoursToDday}} ชั่วโมง {{minutesToDday}} นาที
            {{secondsToDday}} วินาที</b></span>
            </div>
            <div class="row pt-4">

                <!-- <carousel *ngIf="isBannerAvailable">
                    <div class="carousel-cell" *ngFor="let item of flashSaleModel;">
                        <img [src]="item.imageUrl" width="100" height="80">
                        <span>Test</span>
                    </div>
                </carousel> -->

                <!-- <carousel *ngIf="isBannerAvailable" [images]="flashSaleModel" [height]="240" [autoplay]="true" [loop]="true"></carousel> -->
                <table class="w100p">
                    <tr>
                        <td class="pointer td1 w16-6p" *ngFor="let item of flashSaleModel;" (click)="goToProduct(item.code)">
                            <img class="rcorners-flashsale img" [src]="item.imageUrl" width="139" height="185">
                            <div class="p-3">
                                <div>
                                    {{item.title}}
                                    <div class="progress" style="height: 20px;">
                                        <div class="progress-bar progress-bar-striped progress-bar-animated bg-danger" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- list item load more -->
<!-- <product-card class="col-xs-2" *ngFor="let item of listProduct" [model]="item"></product-card> -->

<div class="col-md-12 p-4 pt-2">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10" style="padding: 0px;">
            <div class="search-results" infinite-scroll [infiniteScrollDistance]="5" [infiniteScrollThrottle]="500" (scrolled)="onScrollDown()">
                <product-card class="col-xs-2 p-0" *ngFor="let item of listProduct" [model]="item"></product-card>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>