import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ServiceProviderService } from 'src/app/shares/service-provider.service';
import { Location } from '@angular/common'

@Component({
  selector: 'app-d-search',
  templateUrl: './d-search.component.html',
  styleUrls: ['./d-search.component.css']
})
export class DSearchComponent implements OnInit {
  code:string = '20210429142517-721-539';
  lvShop:string = '';
  codeShop:string = '';
  model: any = [];
  shopInfo: any = {};
  categoryList: any = [];

  constructor(
    private router: Router,
    private serviceProviderService: ServiceProviderService,
    private activatedRoute: ActivatedRoute,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      let model: any = this.activatedRoute.snapshot.params;
      let splitString = [];
      splitString = model.code.split('&');
      this.code = splitString[0];
      this.codeShop = splitString[1];
      this.lvShop = splitString[2];

      this.callReadCategory();
      this.callReadShop();
      this.callRead();
    });
  }

  callReadShop() {
    this.serviceProviderService.post('m/shop/read', { 'code': this.code }).subscribe(response => {
      var data: any = response;
      this.shopInfo = data.objectData[0];
    }, err => {
    });
  }

  callRead() {
    this.serviceProviderService.post('m/shop/goods/read', { 'referenceShopCode': this.code, [this.lvShop] : this.codeShop }).subscribe(response => {
      var data: any = response;
      this.model = data.objectData;
      console.log('model -->',this.model);
      
    }, err => {
    });
  }

  setCategorySelected(categoryCode: string, lv: string) {
    this.codeShop = categoryCode;
    this.lvShop = lv;
    this.callRead();
  }

  callReadCategory() {
    this.serviceProviderService.post('m/shop/category/read', { 'code': this.code }).subscribe(response => {
      var data: any = response;
      this.categoryList = data.objectData;
    }, err => {
    });
  }

  back(): void {
    this.location.back()
  }
}
