<!-- component header type 1 -->
<app-d-t1-header></app-d-t1-header>
<!--  -->

<div class="container _1QwuCJ mt125">
  <app-d-user [menuActive]="'password'"></app-d-user>

  <div class="_3D9BVC">
    <div class="h4QDlo" role="main">
      <form>
        <div class="kJiu0u">
          <div class="ZsX2q-">
            <h1 class="_2xVij2">เปลี่ยนรหัสผ่าน</h1>
            <div class="_2PlnUp">กรุณาอย่าเปิดเผยรหัสผ่านแก่คนอื่นๆ เพื่อความปลอดภัยของบัญชีผู้ใช้คุณเอง</div>
          </div>
          <div class="_2gERne">
            <div class="eSOrcn">
              <div class="_3m9JxV">
                <div class="_1p2sNF">
                  <div class="J7OZkm"><label class="_3W1PU2" for="password">รหัสผ่านปัจจุบัน</label></div>
                  <div class="_38MRMp"><input id="password" class="_2R9TsD _3_Hq9P" type="password" autocomplete="off"
                      name="password" value=""></div><button class="_1fdENx">ลืมรหัสผ่าน?</button>
                </div>
              </div>
            </div>
            <div class="eSOrcn">
              <div class="_3m9JxV">
                <div class="_1p2sNF">
                  <div class="J7OZkm"><label class="_3W1PU2" for="newPassword">รหัสผ่านใหม่</label></div>
                  <div class="_38MRMp"><input id="newPassword" class="_2R9TsD _3_Hq9P" type="password"
                      autocomplete="off" name="newPassword" value=""></div>
                </div>
              </div>
            </div>
            <div class="eSOrcn">
              <div class="_3m9JxV">
                <div class="_1p2sNF">
                  <div class="J7OZkm"><label class="_3W1PU2" for="newPasswordRepeat">ยืนยันรหัสผ่าน</label></div>
                  <div class="_38MRMp"><input id="newPasswordRepeat" class="_2R9TsD _3_Hq9P" type="password"
                      autocomplete="off" name="newPasswordRepeat" value=""></div>
                </div>
              </div>
            </div>
            <div class="eSOrcn">
              <div class="_1DS7fG"></div>
              <div class="_1IcdS-"><button type="button"
                  class="btn btn-solid-primary btn--m btn--inline btn-solid-primary--disabled"
                  aria-disabled="true">ยืนยัน</button></div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
