<!-- header 1 -->
<div>
    <div class="box-he bg-twotone">
      <span style="margin-left: 10px;color: white;" class="glyphicon glyphicon-chevron-left fs2"
        (click)="backClicked()"></span>
      กลับ
    </div>
  </div>

<div *ngIf="!check" class="boxxqr">
    <ngx-qrcode
    [elementType]="elementType"
    [errorCorrectionLevel]="correctionLevel"
    [value]="value"
    alt="Demo QR Code"
    cssClass="bshadow"></ngx-qrcode>
</div>

<!-- <div *ngIf="check">success</div> -->