import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Utilities } from './utilities';

@Injectable({
  providedIn: 'root'
})
export class ServiceProviderService {

  // ng build --base-href "/wemart/" --prod
  // server: string = 'http://localhost:4900/';
  // server: string = 'http://122.155.223.63/td-we-mart-api/';
  server: string = 'https://ssp.we-builds.com/ssp-api/';

  constructor(
    private http: HttpClient,
    private utilities: Utilities,
    ) { }

  post(url, param) {

    param.profileCode = this.utilities.getUserLocalStorage().profileCode;
    param.organization = [];
    param.permission = 'all';

    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*' );
    headers.append('Access-Control-Allow-Methods', 'POST');

    // let options = new RequestOptions();
    // options.headers = headers;
    // param.organization = JSON.parse(localStorage.getItem('organization'));
    return this.http.post(this.server + url, param, { headers: headers });
  }

  getUrl(url) {
    console.log('url >>',url);
    
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*' );
    headers.append('Access-Control-Allow-Methods', 'GET');
    return this.http.get(url, { headers: headers });
  }

  get(url) {
    console.log('url >>',url);
    
    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*' );
    headers.append('Access-Control-Allow-Methods', 'GET');
    
    return this.http.get(url);
  }

  postList(url, param) {

    let headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    return this.http.post(this.server + url, param, { headers: headers });
  }
}

