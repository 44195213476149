<div class="fixed-hd">
<!-- header 1 -->
<div class="col-md-12 pt-3 bg-twotone minDiv">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-5">
            <div class="d-inline dropdown" [class.open]="isOpenedDownload">
                <span class="pointer" (mouseover)="isOpenedDownload = true" (mouseleave)="isOpenedDownload = false">ดาวน์โหลด</span>&nbsp;&nbsp;|&nbsp;&nbsp;ติดตามเราบน&nbsp;
                <ul class="dropdown-menu zidx100" (mouseover)="isOpenedDownload = true" (mouseleave)="isOpenedDownload = false">
                    <li>
                        <div>
                            <img src="./assets/img/qr.png" width="200" height="200">
                        </div>
                        <div>
                            <img src="./assets/img/store.jpeg" width="150">
                        </div>
                    </li>
                </ul>
            </div>
            <!-- <span class="pointer" href="javascript:void(0)" style="color: white;" [popover]="myPopoverDownload">ดาวน์โหลด</span> &nbsp;&nbsp;| &nbsp;&nbsp; ติดตามเราบน &nbsp;
            <popover-content #myPopoverDownload placement="bottom" [animation]="true" [closeOnMouseOutside]="false">
                <div>
                    <img src="./assets/img/qr.png" width="150">
                </div>
                <div>
                    <img src="./assets/img/store.jpeg" width="150">
                </div>
            </popover-content> -->

            <span class="pointer" (click)="openFacebook()">
                <img src="./assets/img/facebook_circle.png" width="15">
            </span> &nbsp;
            <span class="pointer" (click)="openFacebook()">
                <img src="./assets/img/line_circle.png" width="15">
            </span>
        </div>
        <div class="col-md-5 text-right">
            <!-- <div class="d-inline dropdown" [class.open]="isOpenedNotification">
                <span class="pointer" (mouseover)="isOpenedNotification = true" (mouseleave)="isOpenedNotification = false"><span class="glyphicon glyphicon-bell"></span>&nbsp;การแจ้งเตือน</span> &nbsp;&nbsp;&nbsp;
                <ul class="dropdown-menu" (mouseover)="isOpenedNotification = true" (mouseleave)="isOpenedNotification = false">
                    <div class="pointer p-5 fs1-5 w250 text-center">
                        <br>
                        <br>
                        <br>
                        <br>
                        <br> กรุณาเข้าสู่ระบบ
                        <br> <span class="fs1-2">เพื่อดูข้อความแจ้งเตือนของคุณ</span>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                </ul>
            </div> -->
            <span class="pointer" (click)="goToNotification()"><span class="glyphicon glyphicon-bell"></span>&nbsp;การแจ้งเตือน</span> &nbsp;&nbsp;&nbsp;
            <span class="pointer"><span class="glyphicon glyphicon-question-sign"></span>&nbsp;ช่วยเหลือ</span> &nbsp;&nbsp;&nbsp;
            <span *ngIf="userData.profileCode == ''" class="pointer" (click)="goToRegister()">สมัครใหม่</span>
            <span *ngIf="userData.profileCode == ''" class="pointer">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span *ngIf="userData.profileCode == ''" class="pointer" (click)="goToLogin()">เข้าสู่ระบบ</span>
            <span *ngIf="userData.profileCode != ''" class="pointer" (click)="goToProfile()">{{userData.first_name}}&nbsp;{{userData.last_name}}</span>
            <span *ngIf="userData.profileCode != ''" class="pointer">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span *ngIf="userData.profileCode != ''" class="pointer" (click)="logout()">ออกจากระบบ</span>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- header 2 -->
<div class="col-md-12 p-4 pb-0 bg-twotone minDiv">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-2 p-3">
            <!-- <h1>SUKSAPAN</h1> -->
            <a (click)="goToMain()">
                <div class="logo-secondary" style="height: 60px;width: 1ถ0px;"></div>
            </a>
            <!-- <img src="./assets/img/we_mart.png" (click)="goToMain()" width="150"> -->
        </div>
        <div class="col-md-7 p-3">
            <div class="input-group">
                <input type="text" class="form-control" placeholder="12.12 Non-Stop SUKSAPAN PayDay | ส่งฟรี* ขั้นต่ำ 0.-">
                <button type="button" class="btn btn-primary">
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="glyphicon glyphicon-search"></span>&nbsp;&nbsp;&nbsp;&nbsp;
                </button>
            </div>
            <div class="pt-2">
                <span class="pointer fs1-2">กระเป๋า</span> &nbsp;&nbsp;
                <span class="pointer fs1-2">เสื้อผ้า</span> &nbsp;&nbsp;
                <span class="pointer fs1-2">รองเท้า</span> &nbsp;&nbsp;
                <span class="pointer fs1-2">หนังสือ</span> &nbsp;&nbsp;
                <span class="pointer fs1-2">เครื่องเขียน</span> &nbsp;&nbsp;
            </div>
        </div>
        <div class="col-md-2 p-4">
            <div class="d-inline dropstart" [class.open]="isOpenedCart">
                <span class="pointer" (click)="goToCart()" (mouseover)="isOpenedCart = true" (mouseleave)="isOpenedCart = false">
                    &nbsp;&nbsp;&nbsp;&nbsp;<span class="glyphicon glyphicon-shopping-cart fs2"></span>
                </span>
                <!-- <ul class="dropdown-menu" (mouseover)="isOpenedCart = true" (mouseleave)="isOpenedCart = false">
                    <div class="pointer p-5 fs1-5 w200 text-center">
                        <br>
                        <br>
                        <br>
                        <br>
                        <br> กรุณาเข้าสู่ระบบ
                        <br> <span class="fs1-2" (click)="goToCart()">ก่อนทำการช้อปปิ้ง</span>
                        <br>
                        <br>
                        <br>
                        <br>
                    </div>
                </ul> -->
            </div>
        </div>
    </div>
</div>
</div>