<div class="_35LNwy" (click)="selected(model.code)">
  <div class="fBhek2">
    <div class="_25_r8I _2SHkSu">
      <img width="invalid-value" height="invalid-value" alt="{{model.title}}" class="mxM4vG _2GchKS"
        style="object-fit: contain" src="{{model.imageUrl}}">
    </div>
    <div class="_1ObP5d">
      <div class="_1nHzH4" data-sqe="name">
        <div class="PFM7lj">
          <div class="yQmmFK _1POlWt _36CEnF">{{model.title}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
