<div>
  <div
    style="position: relative;width: 100%; height: 150px;object-fit:cover; background-image: url('https://images.unsplash.com/photo-1614481327033-68e5df399653?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=702&q=80');">
    <div style="display: flex; position: absolute;bottom: 0;left: 0;margin-left: 15px;margin-bottom: 10px;">
      <img src="{{userData.imageUrl}}" height="70" width="70" alt="" style="border-radius: 50%;">
      <div style="display: flex;flex-direction: column;justify-content: center;margin-left: 10px;">
        <span>{{userData.first_name}} {{userData.last_name}}</span>
        <div style="display: flex;">
          <span>ผู้ติดตาม&nbsp;10</span>&nbsp;|&nbsp;
          <span>กำลังติดตาม&nbsp;10</span>
        </div>
      </div>
    </div>
    <div style="display: flex; position: absolute;top: 0;right: 0;margin-right: 15px;margin-top: 10px;">
      <span (click)="navTo('cart')" style="margin-left: 10px;color: white;" class="glyphicon glyphicon-cog fs2"></span>
      <span (click)="navTo('cart')" style="margin-left: 10px;color: white;" class="glyphicon glyphicon-shopping-cart fs2"></span>
      <!-- <span style="margin-left: 10px;color: white;" class="glyphicon glyphicon-comment fs2"></span> -->
    </div>
    <div style="display: flex; position: absolute;top: 0;left: 0;margin-left: 15px;margin-top: 10px;">
      <span style="margin-left: 10px;color: white;" class="glyphicon glyphicon-chevron-left fs2" (click)="navTo('')"></span>
    </div>
  </div>
  <div style="display:flex;width: 100%;height: 60px;padding: 10px 0;">
    <div class="btn-tap" style="border-right: solid 2px #f0f0f0;">การซื้อของฉัน</div>
    <div class="btn-tap">โพสต์</div>
  </div>
</div>
