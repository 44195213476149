<!-- header 1 -->
<div class="col-md-12 p-3 bg-twotone">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-5">
        </div>
        <div class="col-md-5 text-right">
            <span class="pointer" (click)="goTo('notification')"><span
          class="glyphicon glyphicon-bell"></span>&nbsp;การแจ้งเตือน</span> &nbsp;&nbsp;&nbsp;
            <span class="pointer" (click)="goTo('contactHelp')"><span
          class="glyphicon glyphicon-question-sign"></span>&nbsp;ช่วยเหลือ</span>
            &nbsp;&nbsp;&nbsp;
            <span *ngIf="userData.profileCode == ''" class="pointer" (click)="goTo('register')">สมัครใหม่</span>
            <span *ngIf="userData.profileCode == ''" class="pointer">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span *ngIf="userData.profileCode == ''" class="pointer" (click)="goTo('login')">เข้าสู่ระบบ</span>
            <span *ngIf="userData.profileCode != ''" class="pointer" (click)="goTo('profile')">{{userData.first_name}}&nbsp;{{userData.first_name}}</span>
            <span *ngIf="userData.profileCode != ''" class="pointer">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
            <span *ngIf="userData.profileCode != ''" class="pointer" (click)="logout()">ออกจากระบบ</span>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- header 2 -->
<div class="col-md-12 p-4 pb-0 bg-white">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-2">
            <!-- <h1>SUKSAPAN</h1> -->

            <a href="/">
                <div class="logo-primary" style="height: 60px;width: 150px;"></div>
            </a>
        </div>
        <div class="col-md-8 p-4">
            <div class="input-group">
                <input type="text" class="form-control border-second" placeholder="7.7 Non-Stop SUKSAPAN PayDay | ส่งฟรี* ขั้นต่ำ 0.-">
                <button type="button" class="btn btn-secondary">
          &nbsp;&nbsp;&nbsp;&nbsp;<span class="glyphicon glyphicon-search"></span>&nbsp;&nbsp;&nbsp;&nbsp;
        </button>
            </div>
        </div>
        <div class="col-md-1 p-4">
        </div>
    </div>
</div>

<!-- coupon -->
<div class="col-md-12 col-sm-12 p-2">
    <div class="col-md-1 col-sm-1"></div>
    <div class="col-md-10 col-sm-10 p-2" style=" border: 1px solid #2CB7D5; border-radius: 4px;background-color: #f1fcff;">
        <span style="color: grey;">เก็บโค้ดส่งฟรี ที่ SUKSAPAN คลิกเลย </span>
        <span style="color: red;">รับโค้ดส่วนลด</span>
    </div>
    <div class="col-md-1 col-sm-1"></div>
</div>

<!-- content -->
<div class="col-md-12 col-sm-12 p-4 pt-2">
    <div class="row">
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-5 col-sm-5 pt-4 left bg-white">
            <div class="row-md-12 row-sm-12">
                <div class="col-md-1 col-sm-1 pt-1">
                    <input type="checkbox" [(ngModel)]="itemSelected" [ngModelOptions]="{standalone: true}" (click)="checkUncheckAll()" />
                </div>
                <div class="col-md-10 col-sm-10 text-left">
                    <h3>สินค้า</h3>
                </div>
            </div>
        </div>
        <div class="col-md-5 col-sm-5 pt-4 bg-white">
            <div class="row">
                <div class="col-md-3 col-sm-3  text-center">
                    <h3>ราคาต่อชิ้น</h3>
                </div>
                <div class="col-md-3 col-sm-3 text-center">
                    <h3>จำนวน</h3>
                </div>
                <div class="col-md-3 col-sm-3 text-center">
                    <h3>ราคารวม</h3>
                </div>
                <div class="col-md-3 col-sm-3 text-center">
                    <h3>แอคชั่น</h3>
                </div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>

<!-- content -->
<div class="col-md-12 col-sm-12 p-4 pt-2">
    <div *ngFor="let i of model; let idxShop = index">
        <!-- shop -->
        <div>
            <div class="row pt-3">
                <div class="col-md-1 col-sm-1"></div>
                <div class="col-md-5 col-sm-5 p-3 left bg-white">
                    <div class="row-md-12 row-sm-12">
                        <div class="col-md-1 col-sm-1 pt-1">
                            <input type="checkbox" [(ngModel)]="i.isSelected" [ngModelOptions]="{standalone: true}" (click)="checkUncheckShop(idxShop)" />
                        </div>
                        <div class="col-md-10 col-sm-10 p-2">
                            <div class="col-md-3"><span class="text-center" style="background-color: #ED5643;padding-left: 5px; padding-right: 5px; align-items: center; color: white;">ร้านแนะนำ</span>
                            </div>
                            <div class="col-md-9 text-left"><span>{{i.shopName}}</span></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5 col-sm-5 pt-4 bg-white"></div>
                <div class="col-md-1"></div>
            </div>
            <!-- product -->
            <div *ngFor="let j of i.items; let idxItem = index">
                <div class="row mt-1">
                    <div class="col-md-1 col-sm-1"></div>
                    <div class="col-md-5 col-sm-5 left bg-white" style="height: 200px;">
                        <div class="row-md-12 row-sm-12 H1pAc">
                            <div class="col-md-1 col-sm-1" style="margin-top: 95px;">
                                <input type="checkbox" [(ngModel)]="j.isSelected" [ngModelOptions]="{standalone: true}" (click)="checkUncheckItem(idxShop,idxItem)" />
                            </div>
                            <div class="col-md-10 col-sm-10 H1pAc">
                                <div class="col-md-3">
                                    <img style="margin-top: 50px" src="{{j.imageUrl}}" height="100px" width="100px" alt="">
                                </div>
                                <div class="row H1pAc">
                                    <div class="col-md-8 text-left"><span>{{j.goodsTitle}}</span></div>
                                    <div class="col-md-4 d-inline dropdown" [class.open]="isOpenedDownload">
                                        <span class="pointer" (click)="isOpenedDownload = false">ตัวเลือกสินค้า: {{j.title}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5 col-sm-5 bg-white">
                        <div class="row" style="height: 100%; align-items: center;">
                            <div class="col-md-3 col-sm-3 text-center">
                                <span><span *ngIf="j.discount > 0"
                    style="text-decoration: line-through;color: grey;">{{formatPrice(j.price)}}</span>&nbsp;&nbsp;{{formatPrice(j.netPrice)}}</span>
                            </div>
                            <div class="col-md-3 col-sm-3 text-center">
                                <div class="row pb-4">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-3 pointer boxUQTY" (click)="updateQty(0,idxShop,idxItem)">-</div>
                                    <div class="col-md-4 pointer">{{j.qty}}</div>
                                    <div class="col-md-3 pointer boxUQTY" (click)="updateQty(1,idxShop,idxItem)">+</div>
                                    <div class="col-md-1"></div>
                                </div>
                            </div>
                            <div class="col-md-3 col-sm-3 text-center">
                                <h3 style="color: red;">{{formatPrice(j.netPrice * j.qty)}}</h3>
                            </div>
                            <div class="col-md-3 col-sm-3 text-center">
                                <h3>ลบ</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-1"></div>
                </div>

            </div>

        </div>
    </div>
</div>

<!-- coupon -->
<div class="col-md-12 col-sm-12">
    <div class="row pb-2">
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-10 col-sm-10 p-2 bg-white">
            <img src="../assets/img/Group6910@2x.png" height="20xp" width="20px" alt="">
            <span>&nbsp;&nbsp;รับส่วนลดมากถึง 5% </span>
            <span style="color: #ED5643;">&nbsp;&nbsp;ดูโค้ดอื่นๆ</span>
        </div>
        <div class="col-md-1 col-sm-1"></div>
    </div>

</div>
<!-- coupon -->
<div class="col-md-12 col-sm-12">
    <div class="row pb-2">
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-10 col-sm-10 p-2 bg-white">
            <img src="../assets/img/Group6910@2x.png" height="20xp" width="20px" alt="">
            <span>&nbsp;&nbsp;ส่วนลดค่าจัดส่ง 20</span>
            <span style="color: #ED5643;">&nbsp;&nbsp;เรียนรู้เพิ่มเติม</span>
        </div>
        <div class="col-md-1 col-sm-1"></div>
    </div>
</div>
<!-- coupon -->

<div class="col-md-12 col-sm-12 p2">
    <div class="row pb-4">
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-10 col-sm-10 p-4 bg-white text-right">
            <img src="../assets/img/Group6910@2x.png" height="20xp" width="20px" alt="">
            <span>&nbsp;&nbsp;โค้ดส่วนลดของ SUKSAPAN</span>
            <span style="color: #ED5643;">&nbsp;&nbsp;เลือกโค้ดส่วนลด</span>
        </div>
        <div class="col-md-1 col-sm-1"></div>
    </div>
</div>

<!-- confirm order -->

<div class="col-md-12 col-sm-12" style="margin-bottom: 20px;">
    <div class="row">
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-5 col-sm-5left bg-white" style="height: 80px;padding-top: 35px;">
            <div class="row-md-12 row-sm-12">
                <div class="col-md-1 col-sm-1">
                    <input type="checkbox" [(ngModel)]="itemSelected" [ngModelOptions]="{standalone: true}" (click)="checkUncheckAll()" />
                </div>
                <div class="col-md-10 col-sm-10 text-left">
                    <span>เลือกทั้งหมด ({{totalQty}})&nbsp;&nbsp;&nbsp;<span class="pointer" (click)="removeSelected()">ลบ</span></span>
                </div>
            </div>
        </div>
        <div class="col-md-5 col-sm-5 right bg-white" style="height: 80px;padding-top: 35px;">
            <div class="row-md-12 row-sm-12">
                <div class="col-md-9 col-sm-9 text-right">
                    <span>รวม ({{totalQty}} สินค้า):&nbsp;&nbsp;<span style="font-size: 22px;">{{formatPrice(totalPrice)}}&nbsp;&nbsp;</span></span>
                </div>
                <div class="col-md-3 col-sm-3 text-left pointer" [ngStyle]="{'background-color:': (totalPrice > 0) ? '#ED5643' : '#707070'}" style="background-color: #ED5643;margin-top: -10px;padding: 8px; text-align: center;border-radius: 3px;" (click)="confirm()">
                    <span style="color: white;">สั่งสินค้า</span>
                </div>
            </div>
        </div>
        <div class="col-md-1 col-sm-1"></div>
    </div>
</div>

<!-- something you like -->
<div class="col-md-12 p-4 pt-2">
    <div class="row">
        <div class="col-md-1"></div>
        <div class="col-md-10 bg-white">
            <div class="row">
                <table class="w100p">
                    <tr>
                        <td class="pointer td1" style="width: 15%;height: 16%;vertical-align: top;" *ngFor="let item of sameProduct;" (click)="goToProduct()">
                            <img class="rcorners3" [src]="item.imageUrl">
                            <div class="p-4">
                                <div class="cs-description">
                                    {{item.title}}
                                </div>
                                <div class="cs-price">
                                    <div>
                                        <p style="color: #ED5643;font-size: 20px;">1000</p>

                                    </div>
                                    <div>
                                        <p style="font-size: 11px;color: #707070;">ขายแล้ว 1พันชิ้น</p>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
                <div class="pointer" style="background-color: white;position: absolute; top: 120px; right: -20px; width: 40px;height: 40px;border-radius: 20px;">
                    <img style="margin-top: 9px;margin-left: 7px;" src="./assets/img/arrow_right.png" height="20px" width="10px" alt=""></div>
            </div>
        </div>
        <div class="col-md-1"></div>
    </div>
</div>